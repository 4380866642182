import React, { useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useForm, Controller } from "react-hook-form";
import SimpleFileEditor from "../../lib/Editor/SimpleFileEditor";

export default function DatasetForm({
  dataset: defaultValues,
  datasets,
  onSave,
}) {
  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = (values) => {
    const data = {
      ...values,
    };
    if (data.testType === "scratch") delete data.testType;
    if (defaultValues.id) {
      data.id = defaultValues.id;
    }
    onSave(data).then(() => reset());
  };
  const { ref: categoryRegisterRef, ...categoryRegister } =
    register("category");
  const { ref: nameRegisterRef, ...nameRegister } = register("name");

  const [testType] = getValues(["testType"]);
  const datasetFilename = useMemo(() => {
    switch (testType) {
      case "scratch":
        return "scratch.json";
      case "phpunit":
        return "test.php";
      case "node":
        return "test.js";
      default:
        return "";
    }
  }, [testType]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ height: "100%" }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ height: "100%" }}
        wrap={"nowrap"}
      >
        <Grid container item justifyContent="space-around">
          <TextField
            inputRef={categoryRegisterRef}
            {...categoryRegister}
            variant="outlined"
            margin="normal"
            required
            InputProps={{
              readOnly: true,
            }}
            id="category"
            label="Category"
            autoFocus
            error={Boolean(errors.category)}
            helperText={errors.category}
          />
          <TextField
            inputRef={nameRegisterRef}
            {...nameRegister}
            variant="outlined"
            margin="normal"
            required
            InputProps={{
              readOnly: true,
            }}
            id="name"
            label="Exercise"
            autoFocus
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
        </Grid>
        <Grid
          container
          item
          justifyContent="space-around"
          style={{ marginBottom: 10 }}
        >
          <FormControlLabel
            control={
              <Controller
                name={"isPrototype"}
                control={control}
                render={({ field: { onChange, ...props } }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Prototype ?"
          />
          <FormControl variant="outlined" style={{ minWidth: 120 }}>
            <InputLabel>Kind of test</InputLabel>
            <Controller
              name="testType"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...props } }) => (
                <Select
                  value={props.value}
                  onChange={(e) => onChange(e.target.value)}
                >
                  <MenuItem value={"node"}>Jest</MenuItem>
                  <MenuItem value={"phpunit"}>PHPUnit</MenuItem>
                  <MenuItem value={"scratch"}>Other</MenuItem>
                </Select>
              )} // props contains: onChange, onBlur and value
            />
          </FormControl>
        </Grid>
        <Controller
          name="dataset"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, ...props } }) => (
            <SimpleFileEditor
              code={{
                [datasetFilename]:
                  typeof props.value === "string"
                    ? props.value
                    : JSON.stringify(props.value),
              }}
              onChange={(code) => onChange(code[datasetFilename])}
            />
          )}
        />
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Grid>
    </form>
  );
}
