import { Box, Dialog } from "@mui/material";
import Markdown from "../../lib/Markdown";

export default function PlaygroundHelp({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xl"}>
      <Box sx={{ p: 4 }}>
        <Markdown>
          {`
# Aide éditeur

## Snippets
Afin d'utiliser les codes des exercices déjà validés, vous pouvez utiliser les snippets.   
Pour cela, il suffit de taper "snippet" et de sélectionner le snippet dans la liste proposée.   
**Exemple : "snippet Basics-camelCase"**

## Raccourcis clavier
- **Ctrl/Cmd + R** : Jouer le code en local
- **Ctrl/Cmd + S** : Soumettre le code
- **Ctrl/Cmd + Shift + F** : Passer en mode plein écran
- **Ctrl/Cmd + -** : Zoom arrière
- **Ctrl/Cmd + =** : Zoom avant
- **Ctrl/Cmd + Shift + 0** : Réinitialiser le zoom
- **Ctrl/Cmd + Shift + P** : Ouvrir la palette de Commands
- **Ctrl/Cmd + Alt + L** : Formatte le document
- **Ctrl/Cmd + Shift + /** : Active/Désactive les commentaires de lignes
`}
        </Markdown>
      </Box>
    </Dialog>
  );
}
