import React, { useCallback, useEffect, useRef, useState } from "react";
import Editor, { loader } from "@monaco-editor/react";
import monacoThemes from "monaco-themes/themes/themelist.json";
import { Box } from "@mui/system";
// import { CommandsRegistry } from "monaco-editor/esm/vs/platform/commands/common/commands";

export const defineTheme = (theme) => {
  return new Promise((res) => {
    if (!monacoThemes[theme]) {
      return res();
    }
    Promise.all([
      loader.init(),
      import(`monaco-themes/themes/${monacoThemes[theme]}.json`),
    ]).then(([monaco, themeData]) => {
      monaco.editor.defineTheme(theme, themeData);
      res();
    });
  });
};

/* const updateKeyBinding = (editor, id, newKeyBinding) => {
  editor._standaloneKeybindingService.addDynamicKeybinding(
    `-${id}`,
    undefined,
    () => {}
  );

  if (newKeyBinding) {
    const { handler, when } = CommandsRegistry.getCommand(id) ?? {};
    if (handler) {
      editor._standaloneKeybindingService.addDynamicKeybinding(
        id,
        newKeyBinding,
        handler,
        when
      );
    }
  }
}; */

function defineShortcuts(editor, customCommands) {
  if (customCommands) {
    customCommands.forEach((command) => {
      editor.addCommand(command.keyBinding, command.handler);
    });
  }
}

function defineKeybindings(editor, customKeybindings) {
  if (customKeybindings) {
    customKeybindings.forEach((keybindRule) => {
      editor.addKeybindingRule(keybindRule);
    });
  }
}

function defineSnippets(monaco, snippets, addedSnippets) {
  if (snippets) {
    Object.entries(snippets).forEach(([language, snippets]) => {
      monaco.current.languages.registerCompletionItemProvider(language, {
        provideCompletionItems: () => {
          return {
            suggestions: snippets.map((snippet) => {
              addedSnippets.current.push(snippet.title);
              return {
                label: snippet.title,
                kind: monaco.current.languages.CompletionItemKind.Function,
                documentation: snippet.description,
                insertText: snippet.code,
              };
            }),
          };
        },
      });
    });
  }
}

export default function MonacoEditor({
  onChange,
  value,
  customCommands: propCommands = [],
  mode,
  snippets,
  readOnly = false,
  ...rest
}) {
  const monaco = useRef(null);
  const editor = useRef(null);
  const addedSnippets = useRef([]);
  const container = useRef([]);
  const [size, setSize] = useState({
    height: "100%",
    width: "100%",
  });

  function handleBeforeMount(e) {
    monaco.current = e;
    defineSnippets(monaco, snippets, addedSnippets);
  }

  const customCommands = useCallback(
    (monaco, editor) => {
      return [
        ...propCommands,
        {
          keyBinding: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Minus],
          handler: () => {
            editor.trigger("keyboard", "editor.action.fontZoomOut", {});
          },
        },
        {
          keyBinding: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Equal],
          handler: () => {
            editor.trigger("keyboard", "editor.action.fontZoomIn", {});
          },
        },
        {
          keyBinding: [
            monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Digit0,
          ],
          handler: () => {
            editor.trigger("keyboard", "editor.action.fontZoomReset", {});
          },
        },
        {
          keyBinding: [
            monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyP,
          ],
          handler: () => {
            editor.trigger("keyboard", "editor.action.quickCommand", {});
          },
        },
        {
          keyBinding: [
            monaco.KeyMod.CtrlCmd | monaco.KeyMod.Alt | monaco.KeyCode.KeyL,
          ],
          handler: () => {
            editor.trigger("keyboard", "editor.action.formatDocument", {});
          },
        },
        {
          keyBinding: [
            monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Slash,
          ],
          handler: () => {
            editor.trigger("keyboard", "editor.action.commentLine", {});
          },
        },
      ];
    },
    [propCommands]
  );

  function handleMount(e) {
    defineShortcuts(e, customCommands(monaco.current, e));
    defineKeybindings(e, []);
    editor.current = e;
  }

  useEffect(() => {
    if (editor.current) {
      defineShortcuts(
        editor.current,
        customCommands(monaco.current, editor.current)
      );
    }
  }, [customCommands, editor.current]);

  useEffect(() => {
    if (monaco.current) {
    }
  }, [snippets, monaco.current]);

  useEffect(() => {
    if (editor.current) {
      editor.current.updateOptions({ readOnly });
      if (snippets) {
        defineSnippets(monaco, snippets, addedSnippets);
      }
    }
  }, [editor.current, readOnly, snippets]);

  useEffect(() => {
    if (container.current && editor.current) {
      const cnt = container.current;
      const ro = new ResizeObserver(([entry]) => {
        editor.current.layout({
          height: entry.contentRect.height,
          width: entry.contentRect.width,
        });
        setSize({
          height: entry.contentRect.height,
          width: entry.contentRect.width,
        });
      });
      ro.observe(cnt);

      return () => {
        ro.disconnect();
      };
    }
  }, [container.current, editor.current]);

  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        position: "relative",
        width: "100%",
      }}
      ref={container}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <Editor
          defaultLanguage={mode}
          value={value}
          height={size.height}
          width={size.width}
          onChange={readOnly ? () => {} : onChange}
          placeholder="Placeholder Text"
          name="code-block"
          theme="vs-dark"
          options={{
            automaticLayout: true,
            padding: {
              top: 8,
            },
          }}
          beforeMount={handleBeforeMount}
          onMount={handleMount}
          {...rest}
        />
      </Box>
    </Box>
  );
}
