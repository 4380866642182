import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const typeMapping = {
  text: "text",
  rect: "rectangular",
  round: "circular",
};

function getPlaceholderComponent({ type, rows = 3, customPlaceholder }) {
  if (customPlaceholder) return customPlaceholder;
  switch (type) {
    case "textRow":
    case "round":
    case "rect":
      return <Skeleton variant={typeMapping[type]} />;
    case "text":
      return (
        <Grid container direction={"column"}>
          {Array.from({ length: rows }, (_, i) => (
            <Skeleton key={i} variant="text" />
          ))}
        </Grid>
      );
    case "media":
      return (
        <Grid container>
          <Grid item>
            <Skeleton variant={"circular"} />
          </Grid>
          <Grid item container direction={"column"}>
            {Array.from({ length: rows }, (_, i) => (
              <Skeleton key={i} variant="text" />
            ))}
          </Grid>
        </Grid>
      );
  }
}

export default function Placeholder({ children, ready, ...placeholderProps }) {
  return ready ? children : getPlaceholderComponent(placeholderProps);
}
