import { TreeView, TreeItem } from "@mui/x-tree-view";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRight from "@mui/icons-material/ChevronRight";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "../../../../../hooks/useQuery";
import PanelLayout from "../PanelLayout";
import useExercisesGroup from "../../../../../hooks/functional/useExercisesGroup";
import useSubmissionsSuccess from "../../../../../hooks/functional/useSubmissionsSuccess";
import { Box } from "@mui/material";
function ListExos() {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, exercisesGroup: categories } = useExercisesGroup();
  const { exosSucceed } = useSubmissionsSuccess(true);
  const params = useQuery();
  const categoryId = params.get("category");
  const exo = params.get("exercise");

  const [expanded, setExpanded] = useState([params.get("category")]);
  const [selected, setSelected] = useState(`${categoryId}**${exo}`);
  useEffect(() => {
    setSelected(`${categoryId}**${exo}`);
  }, [categoryId, exo]);
  const handleToggle = useCallback(
    (e, nodeIds) => {
      setExpanded(nodeIds);
    },
    [expanded]
  );
  const handleSelect = useCallback(
    (e, nodeIds) => {
      if (!nodeIds.includes("**")) return;
      setSelected(nodeIds);
    },
    [selected]
  );

  useEffect(() => {
    if (selected.length) {
      const queryParams = selected.split("**");
      navigate({
        pathname: location.pathname,
        search:
          "?" +
          new URLSearchParams({
            category: queryParams[0],
            exercise: queryParams[1],
          }).toString(),
      });
    }
  }, [selected, categories]);

  return useMemo(
    () =>
      loading ? (
        <CircularProgress />
      ) : (
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          expanded={expanded}
          selected={selected}
        >
          {categories.map((group) => (
            <TreeItem
              key={group.id}
              nodeId={group.id}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1 }}>
                    {group.category_name in exosSucceed &&
                      Object.keys(group.exercices).length ===
                        Object.keys(exosSucceed[group.category_name] ?? {}) && (
                        <CheckIcon style={{ color: "green" }} />
                      )}
                  </Box>
                  {group.category_name}
                </Box>
              }
            >
              {Object.entries(group.exercices)
                .sort((a, b) => a[1].name.localeCompare(b[1].name))
                .map(([key, value]) => (
                  <TreeItem
                    key={key}
                    nodeId={`${group.id}**${key}`}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ minWidth: "24px", mr: 1 }}>
                          {key in (exosSucceed[group.category_name] ?? {}) && (
                            <CheckIcon style={{ color: "green" }} />
                          )}
                        </Box>
                        {value.name}
                      </Box>
                    }
                  />
                ))}
            </TreeItem>
          ))}
        </TreeView>
      ),
    [categories, loading, selected, expanded, exosSucceed]
  );
}

export default function ExercisesPanel(props) {
  return (
    <PanelLayout title={"All Exercises"} {...props}>
      <ListExos />
    </PanelLayout>
  );
}
