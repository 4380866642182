import React, { useMemo, useState } from "react";
import { firestore as db } from "../../../firebaseConfig";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import ExerciseModal from "./Modal";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import useAuth from "../../../hooks/useAuth";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
  getDoc,
} from "@firebase/firestore";
import useLayoutContext from "../../../hooks/useLayoutContext";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Edit from "@mui/icons-material/Edit";

const classes = {
  heroContent: {
    backgroundColor: "background.paper",
    py: 6,
    px: 0,
  },
  cardGrid: {
    paddingTop: 4,
    paddingBottom: 8,
  },
  extendedIcon: {
    marginRight: 1,
  },
};

export default function ExerciseView() {
  useLayoutContext({ title: "Exercises" });
  const [modal, setModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const checkExercise = useMemo(() => [orderBy("lastUpdated", "desc")], []);
  const { data: datasets } = useFirebaseCollection("exercises", checkExercise);

  const _onSubmit = async (values) => {
    const { id, ...data } = values;
    if (values) {
      data.lastUpdated = new Date();
      data.lastUpdator = user.uid;
      if (typeof modal === "boolean") {
        const docRef = await addDoc(collection(db, "exercises"), data);
        setModal({
          id: docRef.id,
          ...(await getDoc(docRef)).data(),
        });
        enqueueSnackbar("Exercise submitted !", { variant: "success" });
      } else {
        await updateDoc(doc(db, "exercises", id), data);
        enqueueSnackbar("Exercise updated !", { variant: "success" });
      }
    }
  };

  const _onDelete = async (values) => {
    await deleteDoc(doc(db, "exercises", values.id));
    enqueueSnackbar("Exercise deleted !", { variant: "success" });
  };

  return (
    <>
      <Grid item container justifyContent="flex-end">
        <Fab
          variant="extended"
          size="large"
          color="primary"
          aria-label="add"
          onClick={() => setModal(true)}
        >
          <Add sx={classes.extendedIcon} />
          Add Exercise Group
        </Fab>
      </Grid>
      <Grid item xs>
        <DataGrid
          disableSelectionOnClick
          pageSize={10}
          rowsPerPageOptions={[10, 30, 50]}
          pagination
          columns={[
            { field: "id", headerName: "ID", hide: true },
            {
              field: "category_name",
              headerName: "Name",
              flex: 1,
            },
            {
              field: "description",
              headerName: "Description",
              flex: 1,
            },
            {
              field: "exercices",
              headerName: "Nb exercises",
              align: "center",
              type: "number",
              valueGetter: (params) =>
                Object.keys(params.row.exercices || {}).length,
            },
            {
              field: "lastUpdated",
              headerName: "Last Updated",
              type: "dateTime",
              minWidth: 150,
              valueFormatter: (params) =>
                Boolean(params.value) &&
                format(params.value.toDate(), "yyyy-MM-dd HH:mm"),
            },
            {
              field: "Actions",
              type: "actions",
              //flex: 1,
              minWidth: 130,
              getActions: (params) => [
                <GridActionsCellItem
                  icon={<Edit />}
                  onClick={() => setModal(params.row)}
                  label="Edit"
                />,
                <GridActionsCellItem
                  icon={<Delete />}
                  onClick={() => _onDelete(params.row)}
                  label="Delete"
                />,
              ],
            },
          ]}
          rows={datasets}
        />
      </Grid>
      <ExerciseModal
        open={modal}
        onClose={() => setModal(false)}
        onSubmit={_onSubmit}
        defaultValues={typeof modal === "boolean" ? undefined : modal}
      />
    </>
  );
}
