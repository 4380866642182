import React from "react";
import { Route, Routes } from "react-router-dom";
import DatasetList from "./Dataset/DatasetsView";
import Exercises from "./Exercise/ExercisesView";
import Users from "./Users/UsersView";
import Groups from "./Groups/GroupsView";
import Submissions from "./Submissions/SubmissionsView";
import ChatDashboard from "./Chat/ChatDashboard";
import AdminLayout from "./AdminLayout";

export default function Admin() {
  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route path="/" element={<h1>Admin</h1>} />
        <Route path="chat" element={<ChatDashboard />} />
        <Route path="datasets" element={<DatasetList />} />
        <Route path="exercises" element={<Exercises />} />
        <Route path="users" element={<Users />} />
        <Route path="groups" element={<Groups />} />
        <Route path="submissions" element={<Submissions />} />
      </Route>
    </Routes>
  );
}
