import React, { Fragment, lazy, Suspense } from "react";

const BaseEditor = lazy(async () => {
  const module = await import("./MonacoEditor");
  return module;
});
export default function JsonEditor(rest) {
  return (
    <Suspense fallback={Fragment}>
      <BaseEditor mode="json" {...rest} />
    </Suspense>
  );
}
