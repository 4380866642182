import { Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";
import Logout from "./Logout";
import ResetPage from "./ResetPage";
import SecurityLayout from "./SecurityLayout";
import SignupPage from "./SignupPage";

export default function SecurityRouting() {
  return (
    <Routes>
      <Route element={<SecurityLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="reset" element={<ResetPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="logout" element={<Logout />} />
      </Route>
    </Routes>
  );
}
