import { Route, Routes } from "react-router-dom";
import CategoryList from "./CategoryList";
import Playground from "./Playground";

export default function ExercisesRouting() {
  return (
    <Routes>
      <Route index element={<CategoryList />} />
      <Route path="playground" element={<Playground />} />
    </Routes>
  );
}
