import React, { useCallback } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Timelapse from "@mui/icons-material/Timelapse";
import Check from "@mui/icons-material/Check";
import Cancel from "@mui/icons-material/Cancel";
import { format } from "date-fns";
import Placeholder from "../lib/Placeholder";
import ListItemButton from "@mui/material/ListItemButton";

export function SubmissionIcon({ submission }) {
  if (submission.state === "processing")
    return <Timelapse style={{ color: "blue" }} />;
  if (submission.state === "succeed")
    return <Check style={{ color: "green" }} />;
  if (["failed", "expired"].includes(submission.state))
    return <Cancel style={{ color: "red" }} />;
  return null;
}

export default function SubmissionListItem({
  submission,
  onClick,
  ready = true,
  title,
  selected = false,
  description,
}) {
  const titleText = useCallback(
    (submission) => {
      if (title) return title(submission);
      return (
        `Version: ${format(submission.submittedAt, "yyyyMMdd-HHmm")}` +
        (submission.processedAt
          ? ` - Processed at: ${format(
              submission.processedAt,
              "yyyy-MM-dd HH:mm"
            )}`
          : "")
      );
    },
    [title]
  );

  const descriptionText = useCallback(
    (submission) => {
      if (description) return description(submission);
      return submission.state === "processing"
        ? "Processing..."
        : !submission.output
        ? "Invalid submission"
        : `${
            submission.output.results.filter((r) => r.status === "KO").length
          } errors, ${
            submission.output.results.filter((r) => r.status === "OK").length
          } success`;
    },
    [description]
  );

  return (
    <ListItemButton onClick={onClick} selected={selected}>
      <Placeholder ready={ready} type="media">
        <ListItemIcon>
          {submission.state === "processing" && (
            <Timelapse style={{ color: "blue" }} />
          )}
          {submission.state === "succeed" && (
            <Check style={{ color: "green" }} />
          )}
          {["failed", "expired"].includes(submission.state) && (
            <Cancel style={{ color: "red" }} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={titleText(submission)}
          secondary={descriptionText(submission)}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </Placeholder>
    </ListItemButton>
  );
}
