import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const StyledBox = styled(Box)({
  textOverflow: "ellipsis",
  overflow: "hidden",
  borderBottom: "2px solid",
  borderColor: grey[300],
});

const StyledBoxTitle = styled(Box, {
  shouldForwardProp: (props) => props !== "active",
})(({ theme, active }) => ({
  borderBottom: active && "1px solid " + grey[300],
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  cursor: "pointer",
}));

const StyledBoxCollapse = styled(Collapse)(({ theme, in: In }) => ({
  flex: In ? 1 : 0,
  padding: In ? theme.spacing(1) : 0,
  backgroundColor: theme.palette.background.paper,
}));

export default function PanelLayout({
  isOpen,
  title,
  children,
  onClick,
  loading = false,
}) {
  return (
    <StyledBox>
      <StyledBoxTitle active={isOpen} onClick={onClick}>
        <Typography noWrap variant="button">
          {title}
        </Typography>
      </StyledBoxTitle>
      <StyledBoxCollapse in={isOpen}>
        {loading ? <CircularProgress /> : children}
      </StyledBoxCollapse>
    </StyledBox>
  );
}
