import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import General from "./General";
import Exercises from "./Exercises";
import Publications from "./Publications";
import { Grid } from "@mui/material";

const _defaultValues = {
  category_name: "",
  exercices: [],
  published: false,
};
const classes = {
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: 2,
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExerciseModal({
  open,
  onClose,
  onSubmit: onSave,
  defaultValues = _defaultValues,
}) {
  const throttleTimeout = useRef(null);
  const [tabValue, setTabValue] = useState(0);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [exerciseGroup, setExerciseGroup] = useState({
    ...defaultValues,
    exercices: Object.keys(defaultValues.exercices).map((key) => ({
      ...defaultValues.exercices[key],
      id: key,
    })),
  });

  useEffect(() => {
    if (!open) return;
    setExerciseGroup({
      ...defaultValues,
      exercices: Object.keys(defaultValues.exercices).map((key) => ({
        ...defaultValues.exercices[key],
        id: key,
      })),
    });
    setTabValue(0);
  }, [defaultValues, open]);

  //const _onSubmit = async (values) => {
  //  const data = { ...values };
  //  if (values) {
  //    onSubmit(values).then(() => onClose());
  //  }
  //};
  //

  useEffect(() => {
    return () => {
      throttleTimeout.current && clearTimeout(throttleTimeout.current);
    };
  }, []);

  const handleExerciseGroupUpdate = (values, autoSave = true) => {
    setExerciseGroup(values);
    if (autoSave) {
      throttleTimeout.current && clearTimeout(throttleTimeout.current);
      throttleTimeout.current = setTimeout(() => {
        onSubmit(false);
      }, 500);
    }
    setNeedUpdate(true);
  };
  const onSubmit = (autoClose = true) => {
    const values = exerciseGroup;
    const data = {
      ...values,
      exercices: values.exercices.reduce((acc, exo) => {
        acc[exo.id] = exo;
        return acc;
      }, {}),
    };
    if (defaultValues.id) {
      data.id = defaultValues.id;
    }
    onSave(data).then(() => {
      setNeedUpdate(false);
      autoClose && onClose();
    });
  };

  return (
    <Dialog
      open={Boolean(open)}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar sx={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" sx={classes.title}>
            Add an exercises group
          </Typography>
          {needUpdate && (
            <Button color="inherit" onClick={onSubmit}>
              Save
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2} direction="column" sx={{ height: "100%" }}>
          <Grid item>
            <Tabs
              value={tabValue}
              onChange={(_, value) => setTabValue(value)}
              indicatorColor="primary"
              textColor="primary"
              centered
              sx={{ marginBottom: 2 }}
            >
              <Tab label="Général" />
              <Tab label="Exercises" />
              {exerciseGroup.id && <Tab label="Publications" />}
            </Tabs>
          </Grid>
          <Grid
            item
            xs
            container
            direction={"column"}
            spacing={2}
            sx={{ overflow: "scroll" }}
          >
            {tabValue === 0 && (
              <General
                exerciseGroup={exerciseGroup}
                onSave={(values) => {
                  handleExerciseGroupUpdate({ ...exerciseGroup, ...values });
                }}
              />
            )}
            {tabValue === 1 && (
              <Exercises
                exercises={exerciseGroup.exercices}
                onSave={(exercices) => {
                  handleExerciseGroupUpdate(
                    { ...exerciseGroup, exercices },
                    true
                  );
                }}
              />
            )}
            {tabValue === 2 && <Publications exerciseGroup={exerciseGroup} />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
