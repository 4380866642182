import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const Form = styled("form")(({ theme }) => ({
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));
const BtnSubmit = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const ResetPage = () => {
  const { resetPassword, error } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const onSubmit = ({ email }) =>
    resetPassword(email)
      .then(() => {
        enqueueSnackbar("Email sent!", { variant: "success" });
        navigate("/security/login");
      })
      .catch((e) => setError("email", { type: "manual", message: e.message }));

  const _error = Boolean(errors.email)
    ? errors.email.message
    : Boolean(error)
    ? error.message
    : null;

  const { ref: emailRegisterRef, ...emailRegister } = register("email");

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        inputRef={emailRegisterRef}
        {...emailRegister}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        autoComplete="email"
        autoFocus
        error={Boolean(_error)}
        helperText={_error}
      />
      <BtnSubmit
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </BtnSubmit>
      <Grid container direction={"column"}>
        <Grid item>
          <Link to="/security/login" variant="body2">
            {"Have an account? Sign In"}
          </Link>
        </Grid>
        <Grid item>
          <Link to="/security/signup" variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ResetPage;
