import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { useMemo, useState } from "react";
import * as Themes from "react-syntax-highlighter/dist/esm/styles/prism";
import Markdown from "../../lib/Markdown";

const exampleMarkdown =
  "\n\
  # Test   \n\
```javascript\n\
const pilote = new Pilote('Mario');   \n\
pilote.receiveData({ state: 'ready' });   \n\
if (pilote.needUpdate()) console.log('Speak ready', pilote.speak());   \n\
// output: 'Speak ready' 'Here we go! I'm Mario'   \n\
pilote.receiveData({ state: 'normal' });   \n\
if (pilote.needUpdate()) console.log('Speak normal', pilote.speak());   \n\
// output: 'Speak normal' ''   \n\
```\n\
";
export default function ThemeMarkdownModal({ open, onClose, value, onSelect }) {
  const themes = useMemo(() => Object.keys(Themes), []);
  const [selectedValue, setSelectedValue] = useState(value || "vscDarkPlus");

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Typography>Theme selector</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={2}>
            <List
              style={{ maxHeight: 300, overflow: "scroll" }}
              disablePadding
              dense
            >
              {themes.map((theme) => (
                <ListItem
                  button
                  key={theme}
                  selected={theme === selectedValue}
                  onClick={() => setSelectedValue(theme)}
                >
                  <ListItemText primary={theme} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs>
            <Markdown theme={selectedValue}>{exampleMarkdown}</Markdown>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSelect(selectedValue)}
          variant="contained"
          color="primary"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
