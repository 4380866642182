import { collection, onSnapshot, query } from "@firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../firebaseConfig";

export default function useFirebaseCollection(coll, constraints = []) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snapshot, setSnapshot] = useState(null);

  useEffect(() => {
    if (constraints === null) {
      setLoading(false);
      return;
    } else {
      setLoading(true);
      setData([]);
    }
    const docRef = collection(firestore, coll);
    if (!Array.isArray(constraints[0])) {
      constraints = [constraints];
    }
    const subscribers = constraints.map((c) => {
      const q = query(docRef, ...c);
      return onSnapshot(
        q,
        (snapshot) => {
          setData([
            ...data,
            ...snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })),
          ]);
          setSnapshot(snapshot);
          setLoading(false);
          setError(null);
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
    });

    return () => subscribers.forEach((s) => s());
  }, [coll, constraints]);

  return {
    data,
    loading,
    error,
    snapshot,
  };
}
