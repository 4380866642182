import React, { useMemo } from "react";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function ExercisesGroupSelector({
  exercisesGroups,
  onSelect,
  selectedValue,
}) {
  return useMemo(() => {
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        onNodeSelect={(e, nodeIds) => onSelect(nodeIds.split("**"))}
        expanded={selectedValue}
        selected={selectedValue.join("**")}
      >
        {exercisesGroups.map((group) => (
          <TreeItem
            key={group.category_name}
            nodeId={group.category_name}
            label={group.category_name}
          >
            {Object.values(group.exercices)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((value) => (
                <TreeItem
                  key={value.id}
                  onLabelClick={() => onSelect(group.id, value.id)}
                  nodeId={`${group.category_name}**${value.id}`}
                  label={value.name}
                />
              ))}
          </TreeItem>
        ))}
      </TreeView>
    );
  }, [exercisesGroups, selectedValue]);
}
