import { Avatar, Badge } from "@mui/material";
import { useMemo } from "react";
import useConsole from "../../../../../hooks/functional/useConsole";
import { blue, red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "1rem",
  height: "1rem",
  border: "none",
  fontSize: "1rem",
  marginLeft: -8,
}));
//const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
//  marginLeft: 2,
//  "& .MuiAvatarGroup-avatar": {
//    border: "none",
//  },
//  "& .MuiAvatar-root": {
//    marginLeft: -4,
//  },
//}));

export default function ConsoleHeader() {
  const { logs } = useConsole();

  const countLogs = useMemo(
    () =>
      logs.reduce(
        (acc, item) => {
          acc[item.method]++;
          return acc;
        },
        { error: 0, log: 0 }
      ),
    [logs]
  );

  return (
    <Badge
      badgeContent={
        <>
          {!countLogs.error && countLogs.log > 0 && (
            <StyledAvatar
              sx={{
                bgcolor: blue[500],
              }}
            >
              {countLogs.log}
            </StyledAvatar>
          )}
          {countLogs.error > 0 && (
            <StyledAvatar sx={{ bgcolor: red[500] }}>
              {countLogs.error}
            </StyledAvatar>
          )}
        </>
      }
    >
      Console
    </Badge>
  );
}
