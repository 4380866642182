import Typography from "@mui/material/Typography";
import { Fragment, useEffect, useMemo, useState } from "react";
import FileToolbar from "./FileToolbar";
import JsEditor from "./JsEditor";
import JsonEditor from "./JsonEditor";
import MarkdownEditor from "./MarkdownEditor";
import PhpEditor from "./PhpEditor";

export default function MultiFileEditor({ code, onChange, ...rest }) {
  const filenames = Object.keys(code);
  const defaultFileName =
    filenames.find((f) => f.startsWith("index.")) || filenames[0];
  const [selectedFile, setSelectedFile] = useState(defaultFileName);
  const Editor = useMemo(() => {
    switch (selectedFile?.split(".")?.pop()) {
      case "json":
        return JsonEditor;
      case "js":
        return JsEditor;
      case "php":
        return PhpEditor;
      case "md":
        return MarkdownEditor;
      default:
        return Fragment;
    }
  }, [selectedFile]);

  useEffect(() => {
    setSelectedFile(defaultFileName);
  }, [defaultFileName]);

  return (
    <>
      {!rest.readOnly && (
        <Typography variant="caption" align="center" style={{ color: "red" }}>
          Appuyer sur <strong>+</strong> pour ajouter un fichier. Chaque fichier
          se trouve dans le même dossier.
          <br />
          Utiliser le système de module <strong>CommonJS</strong>.
        </Typography>
      )}
      <FileToolbar
        readOnly={rest.readOnly}
        files={Object.keys(code)}
        selectedFile={selectedFile}
        onSelect={setSelectedFile}
        onNew={(file) => {
          onChange({ ...code, [file]: "" });
          setSelectedFile(file);
        }}
        onUpdate={(file, value) => {
          const { [file]: content, ...oldCode } = code;
          onChange({ ...oldCode, [value]: content });
        }}
        onDelete={(file) => {
          const { [file]: content, ...oldCode } = code;
          onChange(oldCode);
          setSelectedFile(Object.keys(oldCode)[0]);
        }}
      />
      <Editor
        value={code[selectedFile]}
        path={selectedFile}
        onChange={(value) => onChange({ ...code, [selectedFile]: value })}
        {...rest}
      />
    </>
  );
}
