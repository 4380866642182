import React, { useEffect, useState } from "react";
import { functions } from "../../../firebaseConfig";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Star from "@mui/icons-material/Star";
import useCancellablePromise, {
  CancelledPromiseError,
} from "../../../hooks/useCancellablePromise";
import UpdateModal from "./UpdateModal";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { httpsCallable } from "@firebase/functions";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import EditIcon from "@mui/icons-material/Edit";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { Tooltip } from "@mui/material";
import useQuery from "../../../hooks/useQuery";
import useLayoutContext from "../../../hooks/useLayoutContext";

function escapeRegExp(value) {
  return value; //.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export default function UsersView() {
  useLayoutContext({ title: "Users" });
  const queryParams = useQuery();
  const [users, setUsers] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise } = useCancellablePromise();
  const [updateModal, setUpdateModal] = useState();
  const { impersonate } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const listUsers = httpsCallable(functions, "listUsers");
    cancellablePromise(listUsers())
      .then(
        (results) =>
          setUsers(Object.values(results.data)) ||
          enqueueSnackbar("Users loaded", { variant: "success" })
      )
      .catch((e) =>
        e instanceof CancelledPromiseError
          ? undefined
          : enqueueSnackbar(e.message, { variant: "danger" })
      );
  }, []);

  useEffect(() => {
    if (queryParams.get("search")) {
      setFilterValue(queryParams.get("search"));
    }
  }, [queryParams]);

  const handleImpersonateUser = async (user) => {
    const impersonateUser = httpsCallable(functions, "impersonateUser");
    cancellablePromise(impersonateUser(user.id))
      .then(
        (results) =>
          impersonate(results.data) ||
          enqueueSnackbar(`Impersonated ${user.email}`, { variant: "success" })
      )
      .catch((e) =>
        e instanceof CancelledPromiseError
          ? undefined
          : enqueueSnackbar(`Failed impersonate ${user.email} : ${e.message}`, {
              variant: "danger",
            })
      );
  };

  const filteredUsers = React.useMemo(() => {
    const searchRegex = new RegExp(escapeRegExp(filterValue), "i");
    return users.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(`${field}:${JSON.stringify(row[field])}`);
      });
    });
  }, [users, filterValue]);

  return (
    <>
      {/* End hero unit */}
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Grid item md={6} xs>
          <TextField
            label="Search value..."
            value={filterValue}
            fullWidth
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </Grid>
        {usersSelected.length > 0 && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginLeft: 16 }}
              onClick={() => setUpdateModal(usersSelected)}
            >
              Update Multiple
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs>
        <DataGrid
          checkboxSelection
          disableSelectionOnClick
          pageSize={10}
          rowsPerPageOptions={[10, 30, 50]}
          pagination
          onSelectionModelChange={(newSelection) => {
            setUsersSelected(newSelection);
          }}
          columns={[
            { field: "id", hide: true },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
              renderCell: (params) => (
                <>
                  {params.row.isAdmin && <Star />}
                  {params.value}
                </>
              ),
            },
            {
              field: "groups",
              headerName: "Groups",
              flex: 1,
              renderCell: ({ value }) =>
                value && (
                  <Tooltip
                    title={
                      <p
                        dangerouslySetInnerHTML={{
                          __html: value.map((v) => v.name).join("<br>"),
                        }}
                      />
                    }
                  >
                    <Typography
                      sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    >
                      {value.map((v) => v.name).join(", ")}
                    </Typography>
                  </Tooltip>
                ),
            },
            {
              field: "_createTime",
              headerName: "Created",
              type: "dateTime",
              minWidth: 200,
              valueGetter: ({ value }) => new Date(value),
            },
            {
              field: "_lastSignin",
              headerName: "Last Signin",
              type: "dateTime",
              minWidth: 200,
              valueGetter: ({ value }) => new Date(value),
            },
            {
              field: "Actions",
              type: "actions",
              //flex: 1,
              minWidth: 130,
              getActions: (params) => [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  onClick={() => setUpdateModal(params.row)}
                  label="Update"
                />,
                <GridActionsCellItem
                  icon={<PlaylistAddCheckIcon />}
                  onClick={() =>
                    navigate({
                      pathname: "/admin/submissions",
                      search: `?user=${params.row.id}`,
                    })
                  }
                  label="Submissions"
                  showInMenu
                />,
                <GridActionsCellItem
                  icon={<SwitchAccountIcon />}
                  onClick={() => handleImpersonateUser(params.row)}
                  label="Impersonate"
                  showInMenu
                />,
              ],
            },
          ]}
          rows={filteredUsers}
        />
      </Grid>
      <UpdateModal
        user={updateModal?.length ? updateModal[0] : updateModal}
        multiple={updateModal?.length}
        onSubmit={(values) => {
          httpsCallable(
            functions,
            "synchroUsers"
          )({
            uids: updateModal?.length ? updateModal : [updateModal.id],
            values,
          }).then(
            () => setUpdateModal(undefined) || enqueueSnackbar("User Updated")
          );
        }}
        onClose={() => setUpdateModal(undefined)}
      />
    </>
  );
}
