import { createContext, useContext, useState } from "react";

const ConsoleContext = createContext({});

export function ConsoleProvider({ children }) {
  const state = useState([]);

  return (
    <ConsoleContext.Provider value={{ logs: state[0], setLogs: state[1] }}>
      {children}
    </ConsoleContext.Provider>
  );
}

export default function useConsole() {
  return useContext(ConsoleContext);
}
