import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import monacoThemes from "monaco-themes/themes/themelist.json";
import { defineTheme } from "../../lib/Editor/MonacoEditor";
import Editor from "../../lib/Editor/SimpleFileEditor";

monacoThemes = {
  ...monacoThemes,
  vs: "Default Light+",
  "vs-dark": "Default Dark+",
  "hc-black": "High Contrast",
};

export default function ThemeModal({ open, onClose, value, onSelect }) {
  const [selectedValue, setSelectedValue] = useState(value || "vs-dark");

  function handleThemeChange(theme) {
    defineTheme(theme).then(() => setSelectedValue(theme));
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Typography>Theme selector</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={2}>
            <List
              style={{ maxHeight: 300, overflow: "scroll" }}
              disablePadding
              dense
            >
              {Object.entries(monacoThemes)
                .sort(([, a], [, b]) =>
                  a.toLowerCase().localeCompare(b.toLowerCase())
                )
                .map(([id, theme]) => (
                  <ListItem
                    button
                    key={id}
                    selected={id === selectedValue}
                    onClick={() => handleThemeChange(id)}
                  >
                    <ListItemText primary={theme} />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs>
            <Editor
              readOnly={true}
              code={{
                "index.js":
                  "function test(arg1) {\n  const val = arg1;\n  if(val === 1) {\n    return val;\n  }\n  return val;\n}",
              }}
              theme={selectedValue}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSelect(selectedValue)}
          variant="contained"
          color="primary"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
