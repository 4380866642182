export function ucfirst(chaine) {
  return chaine.replace(/^\w/, (e) => e.toUpperCase());
}

export function chunks(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

export function arrayUnique(array, callback) {
  return [
    ...Object.values(Object.fromEntries(array.map((i) => [callback(i), i]))),
  ];
}
