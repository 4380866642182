import React from "react";
//import CategoryList from "./CategoryListContainer";
import { Route, Routes } from "react-router-dom";
import { Typography } from "@mui/material";
import Dashboard from "./Dashboard";
import SubmissionScreen from "./SubmissionScreen";
import ExercisesRouting from "./ExercisesRouting";
import Profile from "./Profile";
import { ExercicesGroupProvider } from "../../hooks/functional/useExercisesGroup";

export default function DefaultRouting() {
  return (
    <ExercicesGroupProvider>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="exercises/*" element={<ExercisesRouting />} />
        <Route path="submissions" element={<SubmissionScreen />} />
        <Route path="profile" element={<Profile />} />
        <Route
          path="*"
          exact
          element={<Typography variant="h1">Page not found</Typography>}
        />
      </Routes>
    </ExercicesGroupProvider>
  );
}
