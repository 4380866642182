import { doc, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../firebaseConfig";

export default function useFirebaseDocument(coll, id) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snapshot, setSnapshot] = useState(null);

  useEffect(() => {
    if (!id) {
      return;
    }
    const unsubscribe = onSnapshot(
      doc(firestore, coll, id),
      (snapshot) => {
        setSnapshot(snapshot);
        setLoading(false);
        setError(null);
        setData({
          id,
          ...snapshot.data(),
        });
      },
      (error) => {
        setLoading(false);
        setError(error);
      }
    );

    return () => unsubscribe();
  }, [coll, id]);

  return {
    data,
    loading,
    error,
    snapshot,
  };
}
