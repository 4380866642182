import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { firestore } from "../firebaseConfig";

export default function usePaginatedFirestore(
  coll,
  constraints,
  {
    loadOnMount = true,
    paginateBy,
    limit: limitPerPage = 30,
    processDocument = (doc) => doc.data(),
  }
) {
  const [documents, setDocuments] = useState();
  const [page, setPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const listeners = useRef([]);
  const start = useRef(null);
  const end = useRef(null);

  const { field, dir } = useMemo(
    () =>
      typeof paginateBy === "string"
        ? { field: paginateBy, dir: "ASC" }
        : paginateBy,
    [paginateBy]
  );
  const ref = useMemo(() => collection(firestore, coll), [coll]);

  const getDocuments = useCallback(async () => {
    // query reference for the messages we want
    const q = query(
      ref,
      ...constraints,
      orderBy(field, dir),
      limit(limitPerPage)
    );
    // single query to get startAt snapshot
    const snapshots = await getDocs(q);
    if (snapshots.empty) {
      setDocuments([]);
      setIsLast(true);
      return;
    }
    // save startAt snapshot
    start.current = snapshots.docs[snapshots.docs.length - 1].data();
    // create listener using startAt snapshot (starting boundary)
    const q2 = query(
      ref,
      ...constraints,
      orderBy(field, dir),
      where(field, ">=", start.current[field])
    );
    const listener = onSnapshot(q2, (_documents) => {
      // append new documents to message array
      let results = [];
      _documents.forEach((document) => {
        // filter out any duplicates (from modify/delete events)
        results = results.filter((x) => x.id !== document.id);
        results.push(processDocument(document));
      });
      setDocuments(results);
      if (!isLast && _documents.docs.length < limitPerPage) setIsLast(true);
    });
    // add listener to list
    listeners.current.push(listener);
  }, [ref, field, dir, processDocument, constraints]);

  const getMoreDocuments = useCallback(async () => {
    // single query to get new startAt snapshot
    const q = query(
      ref,
      ...constraints,
      orderBy(field, dir),
      where(field, "<", start.current[field]),
      limit(limitPerPage)
    );
    const snapshots = await getDocs(q);

    // previous starting boundary becomes new ending boundary
    end.current = start.current;
    start.current = snapshots.docs[snapshots.docs.length - 1].data();
    // create another listener using new boundaries
    const _documents = snapshots.docs;
    let results = [...(documents || [])];
    _documents.forEach((document) => {
      results = results.filter((x) => x.id !== document.id);
      results.push(processDocument(document));
    });
    setDocuments(results);
    setPage((page) => page + 1);
    if (!isLast && _documents.length < limitPerPage) setIsLast(true);
  }, [ref, field, dir, processDocument]);

  useEffect(() => {
    if (loadOnMount) {
      getDocuments();
    }
    return () => listeners.current.forEach((listener) => listener());
  }, [constraints]);

  return useMemo(
    () => ({
      documents: documents ?? [],
      ready: documents !== undefined,
      isLast,
      next: getMoreDocuments,
      load: getDocuments,
      currentPage: page,
    }),
    [documents, isLast, constraints, page, getDocuments, getMoreDocuments]
  );
}
