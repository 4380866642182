import { useMemo } from "react";
import Typography from "@mui/material/Typography";
import PanelLayout from "../PanelLayout";
import Markdown from "../../../../lib/Markdown";
import useExercisesGroup from "../../../../../hooks/functional/useExercisesGroup";
import useAuth from "../../../../../hooks/useAuth";
import useQuery from "../../../../../hooks/useQuery";

export default function RulePanel(props) {
  const {
    customData: { preferredMarkdownTheme: markdownTheme = "vscDarkPlus" },
  } = useAuth();
  const { getCategory, loading } = useExercisesGroup();
  const params = useQuery();
  const categoryId = params.get("category");
  const exo = params.get("exercise");

  const category = useMemo(() => getCategory(categoryId), [categoryId]);
  const lastUpdated = useMemo(() => {
    if (!category) return new Date();
    return category?.lastUpdated ?? new Date();
  }, [category?.lastUpdated]);

  const exercise = useMemo(
    () => (exo ? category?.exercices[exo] : undefined),
    [lastUpdated, exo]
  );

  return useMemo(
    () => (
      <PanelLayout
        {...props}
        title={"Énoncé: " + exercise.name}
        loading={loading}
      >
        {exercise?.description && (
          <Markdown theme={markdownTheme}>{exercise?.description}</Markdown>
        )}
        {exercise?.example && (
          <Typography variant="body2">
            <strong>Example:</strong>
            <Markdown theme={markdownTheme}>{exercise?.example}</Markdown>
          </Typography>
        )}
        <Typography variant="body2">
          <strong>Function name: {exercise?.function}</strong>
        </Typography>
      </PanelLayout>
    ),
    [lastUpdated, exo, props, loading]
  );
}
