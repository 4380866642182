import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import ExercisePartialForm from "./ExercisePartialForm";
import ExercisesList from "../../../Default/Exercise/ExercisesList";

export default function Exercises({ exercises = [], onSave }) {
  const [exerciceSelected, setExerciceSelected] = useState(exercises[0]);
  const { handleSubmit, register, reset, watch, control, setValue } = useForm({
    defaultValues: exerciceSelected || {
      defaultValue: { "index.js": "function function_name() {}" },
      description: "",
      example: "",
      function: "function_name",
      name: "Function",
      isMultiFiles: false,
      isLocallyExecutable: true,
      isSnippetsAllowed: true,
      defaultLanguage: "js",
    },
  });
  const values = watch();
  const onPartialSubmit = (values) => {
    if (!exerciceSelected || exerciceSelected.new) {
      onSave([...exercises, values]);
    } else {
      onSave(
        exercises.map((exo) => (exo.id === exerciceSelected.id ? values : exo))
      );
    }
    setExerciceSelected(values);
  };

  useEffect(() => {
    reset(
      !exerciceSelected || exerciceSelected.new
        ? {
            defaultValue: { "index.js": "function function_name() {}" },
            description: "",
            example: "",
            function: "function_name",
            name: "Function",
            isMultiFiles: false,
            isLocallyExecutable: true,
            isSnippetsAllowed: true,
            defaultLanguage: "js",
          }
        : exerciceSelected
    );
  }, [exerciceSelected]);

  useEffect(() => {
    const lng = values.defaultLanguage || "js";
    const filenames = Object.keys(values.defaultValue);
    if (filenames[0] !== "index." + lng) {
      setValue("defaultValue", {
        ["index." + lng]: values.defaultValue[filenames[0]],
      });
    }
  }, [values.defaultLanguage]);

  const exoList = useMemo(() => {
    return (
      <ExercisesList
        onSelect={(exo) => setExerciceSelected(exo)}
        selectedValue={exerciceSelected}
        category={{
          exercices: [
            { new: true, name: "Add exercise", id: "new" },
            ...exercises,
          ],
        }}
      />
    );
  }, [exercises, exerciceSelected]);

  return (
    <Grid item container xs spacing={2}>
      <Grid item xs={12} sm={3} md={2}>
        {exoList}
      </Grid>
      <Grid item xs={12} sm={9} md={10} container direction={"column"}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(onPartialSubmit)(e);
          }}
          noValidate
        >
          <ExercisePartialForm
            register={register}
            values={values}
            control={control}
            additionalButtons={[["save", <Button type="submit">Save</Button>]]}
          />
        </form>
      </Grid>
    </Grid>
  );
}
