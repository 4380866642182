import React, { useMemo } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MarkdownEditor from "../../../lib/Editor/MarkdownEditor";
import { Controller } from "react-hook-form";
import Playground from "../../../Default/Exercise/Playground";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";

const classes = {
  root: {
    width: "100%",
  },
};

function getSteps() {
  return [stepGeneral(), stepExamples(), stepDefaultValue()];
}
function stepExamples() {
  return [
    "Exemples",
    ({ control }) => {
      return (
        <Box sx={{ minHeight: 300, width: "100%", height: "100%" }}>
          <Controller
            control={control}
            name="example"
            shouldUnregister={false}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <MarkdownEditor
                onBlur={onBlur} // notify when input is touched
                onChange={onChange} // send value to hook form
                value={value}
                name={name}
                placeholder="Exemple d'utilisation de la fonction"
              />
            )}
          />
        </Box>
      );
    },
  ];
}

function stepDefaultValue() {
  return [
    "Valeurs par défaut",
    ({ control, values }) => {
      return (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel>Default Language</InputLabel>
              <Controller
                name="defaultLanguage"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ...props } }) => (
                  <Select
                    value={props.value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={"js"}>Js</MenuItem>
                    <MenuItem value={"json"}>Json</MenuItem>
                    <MenuItem value={"php"}>Php</MenuItem>
                  </Select>
                )} // props contains: onChange, onBlur and value
              />
            </FormControl>
          </Grid>
          <Grid item xs sx={{ minHeight: 400 }}>
            <Controller
              control={control}
              name="defaultValue"
              shouldUnregister={false}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Playground
                  onChange={onChange} // send value to hook form
                  value={values.defaultValue}
                  isMultiFiles={values.isMultipleFiles}
                  isSubmittable={false}
                  isLocallyExecutable={false}
                  isSnippetAllowed={false}
                />
              )}
            />
          </Grid>
        </Grid>
      );
    },
  ];
}

function stepGeneral() {
  return [
    "Informations générales",
    ({ register, values, control }) => {
      const { ref: idRef, ...idRegister } = register("id");
      const { ref: nameRef, ...nameRegister } = register("name");
      const { ref: functionRef, ...functionRegister } = register("function");
      const { ref: isMultipleFilesRef, ...isMultipleFilesRegister } =
        register("isMultipleFiles");
      const { ref: isLocallyExecutableRef, ...isLocallyExecutableRegister } =
        register("isLocallyExecutable");
      const { ref: isSnippetsAllowedRef, ...isSnippetsAllowedRegister } =
        register("isSnippetsAllowed");
      return (
        <Grid container direction="column">
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                inputRef={idRef}
                {...idRegister}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Id de l'exercice"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                inputRef={nameRef}
                {...nameRegister}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Nom affiché"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                inputRef={functionRef}
                {...functionRegister}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Function à exporter pour les tests"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={isMultipleFilesRef}
                    {...isMultipleFilesRegister}
                    checked={values.isMultipleFiles}
                  />
                }
                label="Gestion de plusieurs fichiers"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={isLocallyExecutableRef}
                    {...isLocallyExecutableRegister}
                    checked={values.isLocallyExecutable ?? true}
                  />
                }
                label="Exécution local (impossible si plusieurs fichiers)"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={isSnippetsAllowedRef}
                    {...isSnippetsAllowedRegister}
                    checked={values.isSnippetsAllowed ?? true}
                  />
                }
                label="Accès aux snippets"
              />
            </Grid>
          </Grid>
          <Grid item xs sx={{ minHeight: 300 }}>
            <Controller
              control={control}
              name="description"
              shouldUnregister={false}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <MarkdownEditor
                  onBlur={onBlur} // notify when input is touched
                  onChange={onChange} // send value to hook form
                  value={value}
                  name={name}
                  placeholder="Description de l'exercice"
                />
              )}
            />
          </Grid>
        </Grid>
      );
    },
  ];
}

export default function VerticalLinearStepper({
  register,
  control,
  values,
  additionalButtons = [],
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = useMemo(() => getSteps(), []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(([label, Component], index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Grid container spacing={2} direction="column">
                <Grid item xs>
                  <Component
                    register={register}
                    values={values}
                    control={control}
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                  </Grid>
                  {activeStep !== steps.length - 1 && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </Grid>
                  )}
                  {additionalButtons.map(([id, button]) => (
                    <Grid key={id} item>
                      {button}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
