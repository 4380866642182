import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../../firebaseConfig";
import useAuth from "../useAuth";

export default function useSnippets(active) {
  const [exosSucceed, setExosSucceed] = useState(null);
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    if (active && exosSucceed === null) {
      setLoading(true);
      const docRef = doc(firestore, "user-submissions-success", user.uid);
      onSnapshot(docRef, (snapshot) => {
        const data = snapshot.data()?.exercises || {};
        setExosSucceed(data);
        if (loading) setLoading(false);
      });
    }
  }, [active]);

  return { exosSucceed: exosSucceed || {}, loading };
}
