import { createContext, useContext, useEffect, useMemo } from "react";
import usePaginatedFirestore from "../usePaginatedFirestore";
import Submission from "../../models/Submission";
import { where } from "firebase/firestore";
export const SubmissionsContext = createContext();

export function SubmissionProvider({
  filters,
  itemsPerPage = 30,
  processSubmissionData = (doc) => doc,
  children,
}) {
  const constraints = useMemo(() => {
    const { group, ...rest } = filters;
    return Object.keys(rest).map((key) =>
      where(key, rest[key].operator, rest[key].value)
    );
  }, [filters]);

  const { documents, next, load, isLast, ready, currentPage } =
    usePaginatedFirestore("submissions", constraints, {
      loadOnMount: false,
      paginateBy: { field: "submittedAt", dir: "desc" },
      limit: itemsPerPage,
      processDocument: (doc) =>
        processSubmissionData(new Submission({ id: doc.id, ...doc.data() })),
    });

  useEffect(() => {
    if (constraints !== null) {
      load();
    }
  }, [constraints]);

  return (
    <SubmissionsContext.Provider
      value={{
        submissions: documents || [],
        actions: { next, load },
        isLast,
        loading: !ready,
        itemsPerPage,
        currentPage,
      }}
    >
      {children}
    </SubmissionsContext.Provider>
  );
}

export default function useSubmissions() {
  return useContext(SubmissionsContext);
}
