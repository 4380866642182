import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useFirebaseCollection } from "../../../hooks";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import { useMemo } from "react";
import { addDoc, collection, orderBy } from "firebase/firestore";
import { firestore, functions } from "../../../firebaseConfig";
import CommentIcon from "@mui/icons-material/Comment";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import useCancellablePromise, {
  CancelledPromiseError,
} from "../../../hooks/useCancellablePromise";
import useAuth from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import SendMessage from "./SendMessage";

export default function () {
  const navigate = useNavigate();
  const { impersonate } = useAuth();
  const constraints = useMemo(() => [orderBy("date")], []);
  const { data, loading } = useFirebaseCollection("chat", constraints);
  const [groupedMessages, setGroupedMessages] = useState({});
  const { cancellablePromise } = useCancellablePromise();
  const [groupSelected, setGroupSelected] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const chatRef = useRef();
  useEffect(() => {
    if (!loading) {
      const results = { ...groupedMessages };
      data.forEach(({ date, owner, message, id, type }) => {
        results[owner.uid] ??= {
          email: owner.email,
          messages: {},
          notViewed: 0,
        };
        if (
          type === "message" &&
          (!results[owner.uid].lastSeen ||
            results[owner.uid].lastSeen < date.toDate())
        ) {
          results[owner.uid].notViewed++;
        }
        if (type === "response") {
          results[owner.uid].lastSeen = date.toDate();
          results[owner.uid].notViewed = 0;
        }
        results[owner.uid].messages[id] ??= { message, id, type };
      });
      setGroupedMessages(results);
    }
  }, [data, loading]);

  useEffect(() => {
    if (groupSelected) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [groupSelected]);

  const handleSend = (event) => {
    if (event.key === "Enter") {
      const message = event.target.value;
      const data = {
        message,
        owner: {
          uid: groupSelected,
          email: groupedMessages[groupSelected].email,
        },
        date: new Date(),
        type: "response",
      };
      addDoc(collection(firestore, "chat"), data).then(
        () => (event.target.value = "")
      );
    }
  };

  const handleImpersonateUser = async (user) => {
    const impersonateUser = httpsCallable(functions, "impersonateUser");
    cancellablePromise(impersonateUser(user.id))
      .then(
        (results) =>
          impersonate(results.data) ||
          enqueueSnackbar(`Impersonated ${user.email}`, { variant: "success" })
      )
      .catch((e) =>
        e instanceof CancelledPromiseError
          ? undefined
          : enqueueSnackbar(`Failed impersonate ${user.email} : ${e.message}`, {
              variant: "danger",
            })
      );
  };

  return (
    <>
      <Accordion style={{ margin: 10 }}>
        <AccordionSummary>Send message</AccordionSummary>
        <AccordionDetails>
          <SendMessage />
        </AccordionDetails>
      </Accordion>
      <Grid item container spacing={1} xs>
        <Grid item>
          <List style={{ maxHeight: "100%", overflow: "auto" }}>
            {Object.keys(groupedMessages).map((key) => (
              <ListItem
                key={key}
                button
                onClick={() => {
                  setGroupSelected(key);
                  setGroupedMessages({
                    ...groupedMessages,
                    [key]: {
                      ...groupedMessages[key],
                      lastSeen: new Date(),
                      notViewed: 0,
                    },
                  });
                }}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText
                  primary={key}
                  secondary={groupedMessages[key].email}
                />
                {groupedMessages[key].notViewed > 0 && (
                  <ListItemSecondaryAction>
                    <Badge
                      badgeContent={groupedMessages[key].notViewed}
                      color="error"
                    >
                      <CommentIcon />
                    </Badge>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
        {groupSelected && (
          <Grid item xs container spacing={2} direction="column">
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="h5">
                  Id: {groupSelected}
                  <Typography variant="h6">
                    Email: {groupedMessages[groupSelected].email}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    navigate("/admin/submissions", {
                      user: {
                        id: groupSelected,
                        email: groupedMessages[groupSelected].email,
                      },
                    })
                  }
                >
                  Submissions
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 16 }}
                  onClick={() =>
                    handleImpersonateUser({
                      id: groupSelected,
                      email: groupedMessages[groupSelected].email,
                    })
                  }
                >
                  Impersonate
                </Button>
              </Grid>
            </Grid>
            <Grid
              innerRef={chatRef}
              item
              xs
              style={{
                overflowY: "auto",
                height: 0,
              }}
            >
              <List>
                {Object.values(groupedMessages[groupSelected].messages).map(
                  ({ message, id, type }) => (
                    <ListItem key={id} divider>
                      <ListItemText
                        primary={type === "message" ? "User" : "Me"}
                        secondary={message}
                      />
                    </ListItem>
                  )
                )}
              </List>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs md={6}>
                <TextField
                  variant={"filled"}
                  fullWidth
                  label="Type message..."
                  onKeyUp={handleSend}
                />
              </Grid>
              <Grid item>
                <SendIcon />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
