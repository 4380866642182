import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import useSubmissions from "../../../hooks/functional/useSubmissions";
import { SubmissionIcon } from "../../Submission/SubmissionListItem";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Visibility from "@mui/icons-material/Visibility";
import SubmissionModal from "../../Submission/SubmissionModal";

export default function SubmissionsTable() {
  const [submissionSelected, setSubmissionSelected] = useState(null);
  const { submissions, loading, actions, isLast, itemsPerPage, currentPage } =
    useSubmissions();
  const [page, setPage] = useState(currentPage - 1);

  useEffect(() => {
    setPage(currentPage - 1);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    // We have the cursor, we can allow the page transition.
    if (newPage === 0 || newPage < currentPage) {
      setPage(newPage);
    } else {
      actions.next();
    }
  };

  const filteredData = useMemo(() => {
    return submissions.slice(itemsPerPage * page, itemsPerPage * (page + 1));
  }, [submissions, itemsPerPage, page]);

  const columns = useMemo(() => {
    const defs = [
      {
        field: "id",
        headerName: "State",
        width: 70,
        align: "center",
        renderCell: ({ row }) => <SubmissionIcon submission={row} />,
      },
      {
        field: "exercise",
        headerName: "Exercise",
        flex: 1,
        valueGetter: ({ row: submission }) =>
          `${submission.exercise.category} - ${submission.exercise.name}`,
      },
      {
        field: "results",
        headerName: "Results",
        flex: 1,
        valueGetter: ({ row: submission }) =>
          submission.state === "processing"
            ? "Processing..."
            : !submission.output
            ? "Invalid submission"
            : `${
                submission.output.results.filter((r) => r.status === "KO")
                  .length
              } errors, ${
                submission.output.results.filter((r) => r.status === "OK")
                  .length
              } success`,
      },
      {
        field: "submittedAt",
        headerName: "Version",
        minWidth: 200,
        valueGetter: ({ value }) => format(value, "yyyyMMdd-HHmm"),
      },
      {
        field: "Actions",
        type: "actions",
        //flex: 1,
        minWidth: 130,
        getActions: ({ row }) => [
          <GridActionsCellItem
            icon={<Visibility />}
            onClick={() => setSubmissionSelected(row)}
            label="Show"
          />,
        ],
      },
    ];
    if (filteredData?.[0]?.owner?.email !== undefined) {
      defs.splice(1, 0, {
        field: "owner",
        headerName: "Email",
        flex: 1,
        valueGetter: ({ value }) => value.email,
      });
    }
    return defs;
  }, [filteredData]);

  return (
    <>
      <DataGrid
        rows={filteredData}
        columns={columns}
        pagination
        pageSize={itemsPerPage}
        rowsPerPageOptions={[itemsPerPage]}
        rowCount={
          isLast ? submissions.length : submissions.length + itemsPerPage
        }
        paginationMode="server"
        onPageChange={handlePageChange}
        page={page}
        loading={loading}
        disableSelectionOnClick
        onRowClick={({ row }) => setSubmissionSelected(row)}
      />
      <SubmissionModal
        submission={submissionSelected}
        onClose={() => setSubmissionSelected(null)}
      />
    </>
  );
}
