import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMemo } from "react";
import { firestore, functions } from "../../../firebaseConfig";
import { useFirebaseCollection } from "../../../hooks";
import useCancellablePromise, {
  CancelledPromiseError,
} from "../../../hooks/useCancellablePromise";
import Autocomplete from "@mui/material/Autocomplete";
import { orderBy, writeBatch, doc } from "firebase/firestore";
import { useEffect } from "react";
import { useRef } from "react";

export default function SendMessage() {
  const { cancellablePromise } = useCancellablePromise();
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState({});
  const inputRef = useRef();
  const [filterGroups, setFilterGroups] = useState({});
  const [filterUsers, setFilterUsers] = useState({});
  const [displayMore, setDisplayMore] = useState(false);
  const checkGroups = useMemo(() => [orderBy("name", "asc")], []);
  const { data: groups } = useFirebaseCollection("groups", checkGroups);

  useEffect(() => {
    const listUsers = httpsCallable(functions, "listUsers");
    cancellablePromise(listUsers())
      .then((results) => setUsers(results.data))
      .catch((e) =>
        e instanceof CancelledPromiseError
          ? undefined
          : enqueueSnackbar(e.message, { variant: "danger" })
      );
  }, []);
  useEffect(() => {
    setFilterUsers([]);
  }, [filterGroups]);
  const recipients = useMemo(() => {
    if (filterUsers.length) {
      return filterUsers;
    }
    if (!Object.keys(filterGroups).length && !filterUsers.length) {
      return [];
    }
    if (Object.keys(filterGroups).length && !filterUsers.length) {
      return Object.values(users).filter(
        (u) =>
          !Object.keys(filterGroups).length ||
          u.groups?.find?.((g) => filterGroups[g.id] !== undefined)
      );
    }
  }, [filterGroups, filterUsers]);

  const handleSend = (event) => {
    const input = inputRef.current;
    if (
      ((event.target === inputRef.current && event.key === "Enter") ||
        event.target !== inputRef.current) &&
      input.value.trim()
    ) {
      const batch = writeBatch(firestore);
      recipients.forEach((recipient) => {
        const id = `${recipient.id}-${Date.now()}`;
        const data = {
          message: input.value,
          owner: {
            uid: recipient.id,
            email: recipient.email,
          },
          date: new Date(),
          type: "response",
        };
        batch.set(doc(firestore, "chat", id), data);
      });
      batch
        .commit()
        .then(() => {
          enqueueSnackbar("Message sent", { variant: "success" });
          input.value = "";
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "danger" });
        });
    }
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item container justifyContent="space-around" spacing={2}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            fullWidth
            options={groups.sort((a, b) => a.name.localeCompare(b.name))}
            value={Object.values(filterGroups)}
            onChange={(event, newVal) => {
              console.log("groups", newVal);
              setFilterGroups(
                newVal.reduce((acc, g) => {
                  acc[g.id] = g;
                  return acc;
                }, {})
              );
            }}
            getOptionLabel={(option) => console.log(option) || option.name}
            renderInput={(params) => (
              <TextField {...params} label="Group" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            fullWidth
            options={Object.values(users)
              .filter(
                (u) =>
                  !Object.keys(filterGroups).length ||
                  u.groups?.find?.((g) => filterGroups[g.id] !== undefined)
              )
              .sort((a, b) => {
                const adomain = a.email.split("@")[1] ?? "unknown",
                  bdomain = b.email.split("@")[1] ?? "unknown";
                return -bdomain.localeCompare(adomain) !== 0
                  ? -bdomain.localeCompare(adomain)
                  : -b.email.localeCompare(a.email);
              })}
            value={filterUsers}
            onChange={(event, newVal) => {
              setFilterUsers(newVal);
            }}
            groupBy={(option) => option.email.split("@")[1] ?? "unknown"}
            getOptionLabel={(option) => console.log(option) || option.email}
            renderInput={(params) => (
              <TextField {...params} label="Users" variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={1} alignItems="center">
          <strong>To: </strong>
          <Typography style={{ marginLeft: 5 }}>
            {recipients.length === 0 && " No recipients selected"}
          </Typography>
          {recipients.slice(0, !displayMore ? 3 : undefined).map((u) => (
            <Chip
              color="primary"
              style={{ marginLeft: 5 }}
              size="small"
              label={u.email}
            />
          ))}
          {!displayMore && recipients.length > 3 && (
            <Chip
              size="small"
              style={{ marginLeft: 10 }}
              color="secondary"
              label={` and ${recipients.length - 3} more`}
              onClick={() => setDisplayMore(true)}
            />
          )}
          {displayMore && (
            <Chip
              size="small"
              label="Show less"
              style={{ marginLeft: 10 }}
              color="secondary"
              onClick={() => setDisplayMore(false)}
            />
          )}
        </Grid>
      </Grid>
      {recipients.length !== 0 && (
        <Grid
          item
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs md={6}>
            <TextField
              fullWidth
              inputRef={inputRef}
              label="Type message..."
              onKeyUp={handleSend}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSend}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
