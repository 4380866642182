import React from "react";
import { AuthProvider } from "./hooks/useAuth";
import { SnackbarProvider } from "notistack";
import Routing from "./components/Routing";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Chat from "./components/Chat";

const theme = createTheme({
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <AuthProvider>
          <Routing />
          {/*<Chat />*/}
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
