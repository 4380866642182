import React, { useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import TransferList from "../../lib/TransferList";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";
import { orderBy } from "@firebase/firestore";

export default function UpdateModal({ user, multiple, onSubmit, onClose }) {
  const [isAdmin, setIsAdmin] = React.useState(user?.isAdmin ?? false);
  const [groups, setGroups] = React.useState(user?.groups ?? []);
  const checkGroups = useMemo(() => [orderBy("name", "asc")], []);
  const { data: groupChoices } = useFirebaseCollection("groups", checkGroups);

  React.useEffect(() => {
    setIsAdmin(user?.isAdmin ?? false);
    setGroups(user?.groups ?? []);
  }, [user]);

  const handleUpdate = () => {
    return onSubmit({
      isAdmin,
      groups: groups.map((g) => ({ id: g.id, name: g.name })),
    });
  };

  return (
    <Dialog open={Boolean(user)} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography>
          Update User claims for {multiple ? "multiple users" : user?.email}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
              color="primary"
            />
          }
          label="Is admin ?"
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            <TransferList
              choices={groupChoices}
              value={groups}
              onChange={setGroups}
            />
          }
          style={{ alignItems: "flex-start" }}
          label="Select Groups"
          labelPlacement="top"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
