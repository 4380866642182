import { BrowserRouter, Route, Routes } from "react-router-dom";
import SecurityRouting from "./Security";
import PrivateRoute from "./lib/PrivateRoute";
import AppRouting from "./App";

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/security/*" element={<SecurityRouting />} />
        <Route path="/*" element={<PrivateRoute element={AppRouting} />} />
      </Routes>
    </BrowserRouter>
  );
}
