import { useEffect, useState } from "react";

const obj = {
  ref: null,
  callbacks: [],
  addListener(call) {
    this.callbacks.push(call);
  },
  removeListener(call) {
    this.callbacks = this.callbacks.filter((c) => c !== call);
  },
  setRef(ref) {
    this.ref = ref;
    this.callbacks.forEach((call) => call(ref));
  },
  getRef() {
    return this.ref;
  },
};
export default function useMainSize() {
  const [main, setMain] = useState(obj.getRef());

  useEffect(() => {
    obj.addListener(setMain);
    return () => {
      obj.removeListener(setMain);
    };
  }, []);
  return {
    setRef(element) {
      obj.setRef(element);
    },
    size: {
      offsetWidth: main?.offsetWidth || 0,
      offsetHeight: main?.offsetHeight || 0,
      clientWidth: main?.clientWidth || 0,
      clientHeight: main?.clientHeight || 0,
    },
  };
}
