import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useMemo } from "react";
import useAuth from "../../hooks/useAuth";
import Container from "../lib/Container";
import { SubmissionProvider } from "../../hooks/functional/useSubmissions";
import SubmissionsTable from "../Admin/Submissions/SubmissionsTable";

export default function SubmissionScreen() {
  const { user } = useAuth();
  const filters = useMemo(
    () => ({ owner: { operator: "==", value: user.uid } }),
    [user.uid]
  );

  return (
    <Container>
      <Grid sx={{ height: "100%" }} container spacing={4} direction={"column"}>
        <Grid item>
          <Typography variant="h2">Your submissions</Typography>
        </Grid>
        <Grid item xs container>
          <SubmissionProvider filters={filters} itemsPerPage={15}>
            <SubmissionsTable />
          </SubmissionProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
