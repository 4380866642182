import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { Link, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import useMainSize from "../../hooks/useMainSize";

const navItems = [
  {
    title: "Dashboard",
    path: "/",
    match: /^\/$/,
    icon: DashboardIcon,
  },
  {
    title: "Exercises",
    path: "/exercises",
    match: /^\/exercises/,
    icon: AssignmentIcon,
  },
];

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Main = styled("main")(({ theme }) => ({
  flex: 1,
  display: "flex",
  minHeight: "100vh",
  paddingTop: theme.mixins.toolbar.minHeight,
}));

export default function AppLayout() {
  const { customData, logout } = useAuth();
  const { setRef } = useMainSize();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <Button
            sx={{ textTransform: "none" }}
            variant="text"
            color="inherit"
            component={Link}
            to="/"
          >
            <Typography variant="h6" color="inherit" noWrap>
              Training Camp
            </Typography>
          </Button>
          <Box sx={{ flexGrow: 1, ml: 2 }}>
            {navItems.map(({ title, path, match, icon: Icon }) => (
              <Button key={path} color="inherit" component={Link} to={path}>
                {title}
              </Button>
            ))}
            {customData && customData.isAdmin && (
              <Button color="inherit" component={Link} to="/admin">
                Admin
              </Button>
            )}
          </Box>
          <Tooltip title="Profile" placement="right">
            <IconButton color="inherit" component={Link} to="/profile">
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout" placement="right">
            <IconButton color="inherit" onClick={() => logout()}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Main ref={setRef}>
        <Outlet />
      </Main>
    </Box>
  );
}
