import React from "react";
import useAuth from "../../hooks/useAuth";
import {
  MicrosoftLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { useSnackbar } from "notistack";

const ProviderLogin = () => {
  const { signInWith } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  //const [error, setError] = useState();
  return (
    <>
      <MicrosoftLoginButton
        onClick={() =>
          signInWith("microsoft.com").catch((e) =>
            enqueueSnackbar(e.message, { variant: "error" })
          )
        }
      />
      <GoogleLoginButton
        onClick={() =>
          signInWith("google.com").catch((e) =>
            enqueueSnackbar(e.message, { variant: "error" })
          )
        }
      />
    </>
  );
};

export default ProviderLogin;
