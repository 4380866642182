import React, { useEffect, useState } from "react";
import { functions } from "../../../firebaseConfig";
import Container from "../../lib/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import ThemeEditorModal from "./ThemeEditorModal";
import useAuth from "../../../hooks/useAuth";
import useQuery from "../../../hooks/useQuery";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { httpsCallable } from "@firebase/functions";
import ThemeMarkdownModal from "./ThemeMarkdownModal";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Profile() {
  const { user, customData = {} } = useAuth();
  const params = useQuery();
  const [codeGroup, setCodeGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [isThemeMarkdownModalOpen, setIsThemeMarkdownModalOpen] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const error =
    customData?.groups?.find((gr) => gr.id === codeGroup) !== undefined
      ? "Group already joined"
      : "";

  const handleClick = (data, message) => {
    setLoading(true);
    return httpsCallable(
      functions,
      "editUser"
    )(data)
      .then(() => setCodeGroup("") || enqueueSnackbar(message))
      .catch((e) => enqueueSnackbar("Error: " + e.message, "danger"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (params.has("invitation")) {
      const invitation = params.get("invitation");
      if (
        customData?.groups?.find((gr) => gr.id === invitation) !== undefined
      ) {
        enqueueSnackbar("Invitation already accepted", "danger");
      } else {
        setCodeGroup(params.get("invitation"));
        handleClick(
          {
            groups: [
              ...(customData?.groups ?? []),
              {
                id: invitation,
              },
            ],
          },
          "Invitation accepted"
        );
      }
    }
  }, [params]);

  return (
    <>
      <Container>
        <Grid container spacing={4} direction={"column"}>
          <Grid item>
            <Typography variant="h2">My profile</Typography>
          </Grid>
          <Grid item container spacing={4}>
            <Grid item xs={12} sm={6} container direction={"column"}>
              <TableContainer
                component={Paper}
                style={{ flex: 1, width: "100%" }}
              >
                <Table aria-label="simple table">
                  <TableBody>
                    {[
                      {
                        title: "Registration",
                        value: user.metadata.creationTime,
                      },
                      { title: "ID", value: user.uid },
                      { title: "Name", value: user.displayName },
                      { title: "Email", value: user.email },
                    ].map((row) => (
                      <TableRow key={row.title}>
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell align="left">{row.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6} container direction={"column"}>
              <Card style={{ flex: 1, width: "100%" }}>
                <CardContent>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <Typography gutterBottom variant="h5" component="h2">
                        My groups{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems={"center"}>
                        <TextField
                          placeholder="Code group"
                          value={codeGroup}
                          error={Boolean(error)}
                          helperText={error}
                          size="small"
                          onChange={(e) => setCodeGroup(e.currentTarget.value)}
                        />
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          size="small"
                          loading={loading}
                          style={{ marginLeft: 10 }}
                          disabled={
                            codeGroup.trim().length === 0 || Boolean(error)
                          }
                          onClick={(_) =>
                            handleClick(
                              {
                                groups: [
                                  ...(customData.groups ?? []),
                                  { id: codeGroup },
                                ],
                              },
                              "Group joined"
                            )
                          }
                        >
                          Join group
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <List>
                    {Object.values(customData.groups ?? {}).map((group) => (
                      <ListItem key={group.id}>
                        <ListItemText primary={group.name} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(_) =>
                              handleClick(
                                {
                                  groups: customData.groups.filter(
                                    (gr) => gr.id !== group.id
                                  ),
                                },
                                `Group "${group.name}" left`
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} container direction={"column"}>
              <Card style={{ flex: 1, width: "100%" }}>
                <CardContent>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography gutterBottom variant="h5" component="h2">
                        Settings
                      </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                      <Typography gutterBottom variant="h6" component="h2">
                        Theme Editor ({customData?.preferredTheme || "monokai"})
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsThemeModalOpen(true)}
                      >
                        Change
                      </Button>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                      <Typography gutterBottom variant="h6" component="h2">
                        Theme Markdown (
                        {customData?.preferredMarkdownTheme || "vscDarkPlus"})
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsThemeMarkdownModalOpen(true)}
                      >
                        Change
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <ThemeEditorModal
        open={isThemeModalOpen}
        onClose={() => setIsThemeModalOpen(false)}
        onSelect={(value) =>
          handleClick(
            {
              preferredTheme: value,
            },
            `Default editor theme changed to "${value}"`
          ).then(() => setIsThemeModalOpen(false))
        }
      />
      <ThemeMarkdownModal
        open={isThemeMarkdownModalOpen}
        onClose={() => setIsThemeMarkdownModalOpen(false)}
        onSelect={(value) =>
          handleClick(
            {
              preferredMarkdownTheme: value,
            },
            `Default editor theme changed to "${value}"`
          ).then(() => setIsThemeMarkdownModalOpen(false))
        }
      />
    </>
  );
}
