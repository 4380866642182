import { Box, Grid, styled, Typography } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminMenu from "./AdminMenu";

const BoxTitle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  py: 3,
  padding: 0,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const BoxContent = styled(Grid)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
  flex: 1,
  display: "flex",
  flexDirection: "column",
}));

export default function AdminLayout() {
  const [title, setTitle] = useState("");
  return (
    <Grid container>
      <Grid item>
        <AdminMenu />
      </Grid>
      <Grid item xs container direction={"column"} sx={{ px: 2 }}>
        <BoxTitle>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
          >
            {title}
          </Typography>
        </BoxTitle>
        <BoxContent container direction={"column"} spacing={2}>
          <Outlet context={{ setTitle }} />
        </BoxContent>
      </Grid>
    </Grid>
  );
}
