import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useMemo } from "react";
import useAuth from "../../../hooks/useAuth";
import Container from "../../lib/Container";
import SubmissionList from "../../Submission/SubmissionList";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { format } from "date-fns";

export default function Dashboard() {
  const { user } = useAuth();
  const filters = useMemo(
    () => ({ owner: { operator: "==", value: user.uid } }),
    [user.uid]
  );
  return (
    <Container>
      <Grid container spacing={4} direction={"column"}>
        <Grid item>
          <Typography variant="h2">Dashboard</Typography>
        </Grid>
        <Grid item xs container>
          <Grid item>
            <Typography variant="h5">Last 5 submissions</Typography>
            <SubmissionList
              itemsPerPage={5}
              queryFilters={filters}
              infinite={false}
              itemProps={{
                title: (doc) =>
                  doc.exercise.category +
                  " - " +
                  doc.exercise.name +
                  " - Version: " +
                  `${format(doc.submittedAt, "yyyyMMdd-HHmm")}`,
              }}
            />
            <Button component={Link} to="/submissions">
              See more
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
