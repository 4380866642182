import React, { useMemo, useState } from "react";
import Placeholder from "../lib/Placeholder";
import InfiniteScroll from "react-infinite-scroll-component";
import usePaginatedFirestore from "../../hooks/usePaginatedFirestore";
import SubmissionListItem from "./SubmissionListItem";
import Submission from "../../models/Submission";
import SubmissionModal from "./SubmissionModal";
import { where } from "@firebase/firestore";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

export default function SubmissionList({
  queryFilters,
  displayFilters,
  infinite = true,
  itemsPerPage = 30,
  processSubmissionData = (doc) => doc,
  itemProps = {},
}) {
  const [submissionSelected, setSubmissionSelected] = useState(null);

  const constraints = useMemo(() => {
    const { group, ...rest } = queryFilters;
    return Object.keys(rest).map((key) =>
      where(key, rest[key].operator, rest[key].value)
    );
  }, [queryFilters]);

  const { documents, next, isLast, ready } = usePaginatedFirestore(
    "submissions",
    constraints,
    {
      loadOnMount: true,
      paginateBy: { field: "submittedAt", dir: "desc" },
      limit: itemsPerPage,
      processDocument: (doc) =>
        processSubmissionData(new Submission({ id: doc.id, ...doc.data() })),
    }
  );

  const filteredDocuments = useMemo(() => {
    return documents.filter((doc) => {
      for (let key in displayFilters) {
        const explodedKeys = key.split(".");
        let temp = { ...doc };
        for (let i = 0; i < explodedKeys.length; i++) {
          temp = temp[explodedKeys[i]];
          if (i < explodedKeys.length - 1 && temp === undefined) {
            return false;
          }
          if (i === explodedKeys.length - 1) {
            const value = displayFilters[key];
            const ok = Array.isArray(value)
              ? value.includes(temp)
              : temp === value;
            if (ok === false) return false;
          }
        }
      }
      if (submissionSelected && submissionSelected.id === doc.id) {
        setSubmissionSelected(doc);
      }
      return true;
    });
  }, [documents, displayFilters]);

  return (
    <>
      <List sx={{ flex: 1, maxWidth: "100%", width: "100%" }}>
        <InfiniteScroll
          dataLength={documents.length} //This is important field to render the next data
          next={next}
          hasMore={infinite && ready && !isLast}
          loader={<Placeholder ready={false} type="media"></Placeholder>}
          // below props only if you need pull down functionality
        >
          {filteredDocuments.map((doc) => (
            <SubmissionListItem
              key={doc.id}
              submission={doc}
              onClick={() => setSubmissionSelected(doc)}
              {...itemProps}
            />
          ))}
          {ready && !filteredDocuments.length && (
            <Typography>No submissions found</Typography>
          )}
        </InfiniteScroll>
      </List>
      <SubmissionModal
        submission={submissionSelected}
        onClose={() => setSubmissionSelected(null)}
      />
    </>
  );
}
