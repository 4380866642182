import { addDoc, collection } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { firestore } from "../../../../firebaseConfig";
import useExercisesGroup from "../../../../hooks/functional/useExercisesGroup";
import useAuth from "../../../../hooks/useAuth";
import useQuery from "../../../../hooks/useQuery";
import Playground from "../../Exercise/Playground";
import useSubmissions from "../../../../hooks/functional/useSubmissions";

let rating = 0;

export default function PlaygroundPanel() {
  const { enqueueSnackbar } = useSnackbar();
  const { submissions } = useSubmissions();
  const { user } = useAuth();
  const { getCategory } = useExercisesGroup();
  const params = useQuery();
  const categoryId = params.get("category");
  const exo = params.get("exercise");

  const submissionsProcessing = useMemo(() => {
    const nb = submissions.filter(
      (sub) => sub.getState() === "processing"
    ).length;
    rating = nb;
    return nb;
  }, [submissions]);
  const category = useMemo(() => getCategory(categoryId), [categoryId]);
  const lastUpdated = useMemo(() => {
    if (!category) return new Date();
    return category?.lastUpdated ?? new Date();
  }, [category?.lastUpdated]);

  const exercise = useMemo(
    () => (exo ? category?.exercices[exo] : undefined),
    [lastUpdated, exo]
  );

  const code = useMemo(() => {
    if (exercise)
      return typeof exercise.defaultValue === "string"
        ? { "index.js": exercise.defaultValue }
        : exercise.defaultValue;
    return null;
  }, [exercise]);

  const onSubmit = (code) => {
    if (rating >= 3) {
      enqueueSnackbar(
        "Rate limit : You already have 3 processing submissions",
        { variant: "error" }
      );
      return;
    }
    rating++;
    const data = {
      code,
      exercise: {
        name: exercise.id,
        category: category.category_name,
      },
      owner: user.uid,
    };

    addDoc(collection(firestore, "submissions"), data).then(() =>
      enqueueSnackbar("Exercise submitted !", { variant: "info" })
    );
  };

  return (
    <Playground
      value={code}
      onSubmit={onSubmit}
      isSubmittable={submissionsProcessing < 3}
      isSubmittableMessage={
        submissionsProcessing >= 3
          ? "Rate limit : You already have 3 processing submissions"
          : null
      }
      isMultiFiles={!!exercise?.isMultipleFiles}
      isSnippetsAllowed={[undefined, true].includes(
        exercise?.isSnippetsAllowed
      )}
      isLocallyExecutable={[undefined, true].includes(
        exercise?.isLocallyExecutable
      )}
      autoSave={true}
      saveKey={JSON.stringify({ categoryId, exo })}
    />
  );
}
