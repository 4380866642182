import { Route, Routes } from "react-router-dom";
import Admin from "../Admin";
import DefaultRouting from "../Default";
import AppLayout from "./AppLayout";

export default function AppRouting() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/admin/*" element={<Admin />} />
        <Route path="*" element={<DefaultRouting />} />
      </Route>
    </Routes>
  );
}
