import Cancel from "@mui/icons-material/Cancel";
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import useSubmissions from "../../../../hooks/functional/useSubmissions";
import useAuth from "../../../../hooks/useAuth";
import MultiFileEditor from "../../../lib/Editor/MultiFileEditor";
import SimpleFileEditor from "../../../lib/Editor/SimpleFileEditor";
import SubmissionListItem from "../../../Submission/SubmissionListItem";
import { TestResults } from "../../../Submission/SubmissionModal";
import useQuery from "../../../../hooks/useQuery";

export default function SubmissionPanel() {
  const params = useQuery();
  const { submissions, loading, actions, isLast } = useSubmissions();
  const [submissionSelected, setSubmissionSelected] = useState(null);
  const {
    customData: { preferredTheme },
  } = useAuth();
  const codeToDisplay = useMemo(() => {
    if (!submissionSelected) return "";
    let value = "";
    if (submissionSelected.output) value = submissionSelected.output.code_used;
    else value = submissionSelected.code;
    value = typeof value === "string" ? { "index.js": value } : value;
    return Object.entries(value).reduce((acc, [key, value]) => {
      acc[`submissions/${key}`] = value;
      return acc;
    }, {});
  }, [submissionSelected]);

  const categoryId = params.get("category");
  const exo = params.get("exercise");
  useEffect(() => {
    setSubmissionSelected(null);
  }, [categoryId, exo]);

  const Editor = useMemo(
    () =>
      Object.keys(codeToDisplay).length === 1
        ? SimpleFileEditor
        : MultiFileEditor,
    [codeToDisplay]
  );

  return (
    <Grid
      container
      sx={{
        height: "100%",
      }}
    >
      <Grid
        item
        container
        direction={"column"}
        xs
        sx={{ height: "100%", overflow: "scroll", borderRight: "1px solid" }}
      >
        <List
          sx={{
            maxWidth: "100%",
          }}
          disablePadding
        >
          {loading && <CircularProgress />}
          {!loading && (
            <>
              {submissions.map((doc) => (
                <SubmissionListItem
                  key={doc.id}
                  submission={doc}
                  selected={submissionSelected === doc}
                  onClick={() =>
                    setSubmissionSelected(
                      submissionSelected === doc ? null : doc
                    )
                  }
                />
              ))}
              {!submissions.length && (
                <Typography m={2} color={"primary"}>
                  No submissions found
                </Typography>
              )}
              {!isLast && (
                <ListItemButton onClick={actions.next}>
                  Afficher plus
                </ListItemButton>
              )}
            </>
          )}
        </List>
      </Grid>
      {submissionSelected !== null && (
        <>
          <Grid
            item
            container
            xs={9}
            sx={{ height: "100%", overflow: "scroll" }}
          >
            <Grid item xs sx={{ overflowY: "scroll", maxHeight: "100%" }}>
              {submissionSelected.output && (
                <TestResults results={submissionSelected.output.results} />
              )}
              {submissionSelected.output &&
                submissionSelected.output.results.length === 1 && (
                  <ListItem style={{ marginLeft: 16 }}>
                    <ListItemIcon>
                      <ListItemIcon>
                        <Cancel style={{ color: "red" }} />
                      </ListItemIcon>
                    </ListItemIcon>
                    <ListItemText
                      primary={"Something went wrong with your code"}
                      secondaryTypographyProps={{
                        component: "p",
                        style: { whiteSpace: "pre-line" },
                      }}
                    />
                  </ListItem>
                )}
              {submissionSelected.isExpired() && (
                <ListItem style={{ marginLeft: 16 }}>
                  <ListItemIcon>
                    <ListItemIcon>
                      <Cancel style={{ color: "red" }} />
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Timeout"}
                    secondary={"Check for infinite loops or memory issues"}
                    secondaryTypographyProps={{
                      component: "p",
                      style: { whiteSpace: "pre-line" },
                    }}
                  />
                </ListItem>
              )}
            </Grid>
            <Grid item xs>
              <Editor
                readOnly={true}
                code={codeToDisplay}
                theme={preferredTheme || "monokai"}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
