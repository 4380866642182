import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useAuth from "../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";

const Form = styled("form")(({ theme }) => ({
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));
const BtnSubmit = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const LoginPage = () => {
  const { login, error } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const onSubmit = ({ email, password }) =>
    login(email, password)
      .then(() => {
        enqueueSnackbar("Logged In!", { variant: "success" });
      })
      .catch((e) => setError("email", { type: "manual", message: e.message }));

  const _error = Boolean(errors.email)
    ? errors.email.message
    : Boolean(error)
    ? error.message
    : null;

  const { ref: emailRegisterRef, ...emailRegister } = register("email");
  const { ref: passwordRegisterRef, ...passwordRegister } =
    register("password");

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        inputRef={emailRegisterRef}
        {...emailRegister}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        autoComplete="email"
        autoFocus
        error={Boolean(_error)}
        helperText={_error}
      />
      <TextField
        inputRef={passwordRegisterRef}
        {...passwordRegister}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <BtnSubmit type="submit" fullWidth variant="contained" color="primary">
        Login
      </BtnSubmit>
    </Form>
  );
};

export default LoginPage;
