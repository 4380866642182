import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function UpdateModal({ group, onSubmit, onClose }) {
  const [name, setName] = React.useState(group?.name ?? "");

  React.useEffect(() => {
    setName(group?.name ?? "");
  }, [group]);

  const handleUpdate = () => {
    return onSubmit({
      id: group?.id,
      name,
    });
  };

  return (
    <Dialog open={Boolean(group)} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography>{group?.id ? "Update" : "Create"} group</Typography>
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          value={name}
          sx={{ mt: 1 }}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          {group?.id ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
