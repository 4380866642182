import { Fragment, useMemo } from "react";
import JsEditor from "./JsEditor";
import JsonEditor from "./JsonEditor";
import MarkdownEditor from "./MarkdownEditor";
import PhpEditor from "./PhpEditor";

export default function SimpleFileEditor({ code, onChange, ...rest }) {
  const filename = Object.keys(code)[0];
  const Editor = useMemo(() => {
    switch (filename?.split(".")?.pop()) {
      case "json":
        return JsonEditor;
      case "js":
        return JsEditor;
      case "php":
        return PhpEditor;
      case "md":
        return MarkdownEditor;
      default:
        return () => <Fragment />;
    }
  }, [filename]);

  return (
    <Editor
      value={code[filename]}
      path={filename}
      onChange={(value) => onChange({ ...code, [filename]: value })}
      {...rest}
    />
  );
}
