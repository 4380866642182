import React, { useMemo } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Placeholder from "../../lib/Placeholder";
import { ucfirst } from "../../../utils/string";

const placeholderArray = Array.apply(null, Array(3)).map(function (x, i) {
  return { id: i };
});

export default function ExercisesList({ category, onSelect, selectedValue }) {
  const lastUpdated = useMemo(() => {
    if (!category) return new Date();
    return category?.lastUpdated ?? new Date();
  }, [category]);

  return useMemo(() => {
    const exercicesList = Object.values(
      category?.exercices ?? placeholderArray
    ).sort((a, b) => a.name && a.name.localeCompare(b.name));

    return (
      <List component="nav">
        {exercicesList.map((value) => {
          const exercice = value;

          return (
            <ListItem
              key={value.id}
              button
              selected={selectedValue && selectedValue.id === value.id}
              onClick={() =>
                category && onSelect({ id: value.id, ...exercice })
              }
            >
              <Placeholder ready={Boolean(category)} type="textRow">
                <ListItemText primary={ucfirst(exercice?.name ?? "")} />
              </Placeholder>
            </ListItem>
          );
        })}
      </List>
    );
  }, [lastUpdated, selectedValue]);
}
