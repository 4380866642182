import { useCallback, useEffect, useRef } from "react";

export default function useThrottling(time) {
  const throttleTimeout = useRef(null);
  const throttling = useCallback(
    (fn) => {
      if (throttleTimeout.current) {
        clearTimeout(throttleTimeout.current);
      }
      throttleTimeout.current = setTimeout(() => {
        fn();
      }, time);
    },
    [time]
  );

  useEffect(() => {
    return () => {
      throttleTimeout.current && clearTimeout(throttleTimeout.current);
    };
  }, []);
  return throttling;
}
