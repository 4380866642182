import React, { useState } from "react";
import Placeholder from "../../lib/Placeholder";
import UserFilters from "../Chat/UserFilters";
import useQuery from "../../../hooks/useQuery";
import { useMemo } from "react";
import useLayoutContext from "../../../hooks/useLayoutContext";
import { Grid } from "@mui/material";
import { SubmissionProvider } from "../../../hooks/functional/useSubmissions";
import SubmissionsTable from "./SubmissionsTable";

export default function SubmissionsView() {
  useLayoutContext({ title: "Submissions" });
  const query = useQuery();
  const user = useMemo(() => query.get("user"), [query]);
  const [uiFilters, setUiFilters] = useState({
    groups: [],
    users: (user && [{ id: user }]) || [],
  });
  const [users, setUsers] = useState({});
  const [ready, setReady] = useState(false);

  //const enhancedSubmissions = React.useMemo(
  //  () =>
  //    submissions &&
  //    submissions.map((sub) => ({
  //      ...sub,
  //      owner: user || users[sub.owner],
  //    })),
  //  [users, submissions, user?.id]
  //);

  const queryFilters = useMemo(() => {
    const filters = {};
    if (uiFilters.users.length) {
      filters.owner = {
        operator: "in",
        value: uiFilters.users.map((u) => u.id),
      };
    }
    return filters;
  }, [uiFilters]);

  return (
    <>
      {/* Hero unit */}
      <Grid item>
        <UserFilters
          value={uiFilters}
          onChange={setUiFilters}
          onReady={() => setReady(true)}
          readOnly={user !== null}
          onUsersLoaded={setUsers}
        />
      </Grid>
      {/* End hero unit */}
      <Grid item xs>
        <Placeholder ready={ready}>
          {ready && (
            <SubmissionProvider
              filters={queryFilters}
              title={JSON.stringify(queryFilters)}
              processSubmissionData={(submission) => {
                /** @var {Submission} submission */
                submission.setOwner(users[submission.getOwner()]);
                return submission;
              }}
            >
              <SubmissionsTable />
            </SubmissionProvider>
          )}
        </Placeholder>
      </Grid>
    </>
  );
}
