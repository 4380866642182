import React, { useMemo, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import useAuth from "../../../../../hooks/useAuth";
import { firestore as db } from "../../../../../firebaseConfig";
import { useSnackbar } from "notistack";
import Add from "@mui/icons-material/Delete";
import Delete from "@mui/icons-material/Delete";
import { format } from "date-fns";
import Form from "./Form";
import useFirebaseCollection from "../../../../../hooks/useFirebaseCollection";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  where,
} from "@firebase/firestore";
import { TextField } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function Exercises({ exerciseGroup }) {
  const { user } = useAuth();
  const [filterValue, setFilterValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const checkPublications = useMemo(
    () =>
      (exerciseGroup.id && [
        where("category.id", "==", exerciseGroup.id),
        orderBy("group.name", "asc"),
      ]) ||
      null,
    [exerciseGroup.id]
  );
  const { data: publications } = useFirebaseCollection(
    "exercises-publication",
    checkPublications
  );

  const filteredPublications = useMemo(() => {
    const searchRegex = new RegExp(filterValue, "i");
    return publications?.filter((group) => {
      return Object.keys(group).some((field) => {
        return searchRegex.test(`${field}:${JSON.stringify(group[field])}`);
      });
    });
  }, [publications, filterValue]);

  const onSubmit = async (values) => {
    const { id, ...data } = values;
    if (values) {
      data.lastUpdator = user.uid;
      data.lastUpdated = new Date();
      //if (typeof modal === "boolean") {
      data.category = {
        id: exerciseGroup.id,
        name: exerciseGroup.category_name,
      };
      await addDoc(collection(db, "exercises-publication"), data);
      enqueueSnackbar("Publication submitted !", { variant: "success" });
      //} else {
      //  await updateDoc(doc(db, "exercises-publication", id), data);
      //  enqueueSnackbar("Publication updated !", { variant: "success" });
      //}
      setModal(false);
    }
  };

  const _onDelete = async (values) => {
    if (window.confirm("Are you sure you want to delete this publication ?")) {
      await deleteDoc(doc(db, "exercises-publication", values.id));
      enqueueSnackbar("Publication deleted !", { variant: "success" });
    }
  };

  return (
    <>
      <Grid item container spacing={2} justifyContent="space-between">
        <Grid item xs md={6}>
          <TextField
            label="Search value..."
            value={filterValue}
            fullWidth
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Fab
            variant="extended"
            size="large"
            color="primary"
            aria-label="add"
            onClick={() => setModal({})}
          >
            <Add sx={{ mr: 1 }} />
            Publish to a Group
          </Fab>
        </Grid>
      </Grid>
      <Grid item>
        <Collapse in={Boolean(modal)}>
          <Paper elevation={3} sx={{ mx: 1, px: 2, py: 2 }}>
            <Form
              onSave={onSubmit}
              defaultValues={typeof modal === "boolean" ? undefined : modal}
              onClose={() => setModal(false)}
            />
          </Paper>
        </Collapse>
      </Grid>
      <Grid item xs>
        <DataGrid
          disableSelectionOnClick
          pageSize={10}
          rowsPerPageOptions={[10, 30, 50]}
          pagination
          columns={[
            { field: "id", hide: true },
            {
              field: "group",
              headerName: "Group",
              flex: 1,
              valueGetter: ({ row }) => row.group.name,
            },
            {
              field: "dateStart",
              headerName: "From",
              minWidth: 150,
              valueGetter: ({ row }) =>
                (row.timeLimited &&
                  format(row.dateStart.toDate(), "yyyy-MM-dd HH:mm")) ||
                "",
            },
            {
              field: "dateEnd",
              headerName: "To",
              minWidth: 150,
              valueGetter: ({ row }) =>
                (row.timeLimited &&
                  format(row.dateEnd.toDate(), "yyyy-MM-dd HH:mm")) ||
                "",
            },
            {
              field: "lastUpdated",
              headerName: "Last Update",
              minWidth: 150,
              valueGetter: ({ row }) =>
                (row.lastUpdated &&
                  format(row.lastUpdated.toDate(), "yyyy-MM-dd HH:mm")) ||
                "",
            },
            {
              field: "Actions",
              type: "actions",
              //flex: 1,
              minWidth: 130,
              getActions: (params) => [
                <GridActionsCellItem
                  icon={<Delete />}
                  onClick={() => _onDelete(params.row)}
                  label="Delete"
                />,
              ],
            },
          ]}
          rows={filteredPublications}
        />
      </Grid>
    </>
  );
}
