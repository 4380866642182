import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";

const config = {
  apiKey: "AIzaSyCeG6fj2HDLtOgNg0OU90gxTj3hMFvRVo4",
  authDomain: "wsf-courses-1920.firebaseapp.com",
  databaseURL: "https://wsf-courses-1920.firebaseio.com",
  projectId: "wsf-courses-1920",
  storageBucket: "wsf-courses-1920.appspot.com",
  messagingSenderId: "906776162340",
  appId: "1:906776162340:web:8be5df628483ac58e5e8b1",
  measurementId: "G-M2Q7GCW6FS",
};

const firebaseApp = initializeApp(config);
const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);
const remoteConfig = getRemoteConfig(firebaseApp);

if (
  process.env.REACT_APP_EMULATORS &&
  window.location.hostname === "localhost"
) {
  connectFirestoreEmulator(firestore, "localhost", 6060);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://localhost:9099");
}
remoteConfig.settings.minimumFetchIntervalMillis = 15000;
export { firebaseApp, firestore, auth, functions, remoteConfig };
