import { auth } from "../firebaseConfig";
import React, {
  useState,
  createContext,
  useMemo,
  useContext,
  useEffect,
} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {
  createUserWithEmailAndPassword,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "@firebase/auth";
import useFirebaseDocument from "./useFirebaseDocument";
import { defineTheme } from "../components/lib/Editor/MonacoEditor";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [customData, setCustomData] = useState(undefined);
  const [error /*, setError*/] = useState();
  const [ready, setReady] = useState(false);
  const { data, loading } = useFirebaseDocument("user-claims", user?.uid);

  useEffect(() => {
    const subscribe = onAuthStateChanged(auth, setUser);

    return () => subscribe();
  }, []);

  useEffect(() => {
    if (user) {
      if (!loading) {
        if (data && data._lastCommitted) {
          if (
            !customData ||
            (customData._lastCommitted &&
              !data._lastCommitted.isEqual(customData._lastCommitted))
          ) {
            setCustomData({ ...data, groups: data.groups || [] });
          }
        } else if (!data) {
          setCustomData({
            isAdmin: false,
            _lastCommitted: new Date(),
            groups: [],
          });
        }
        defineTheme(data?.preferredTheme).then(() => setReady(true));
      }
    } else if (user === null) {
      setReady(true);
    }
  }, [user, data]);

  const actions = useMemo(
    () => ({
      login: async (email, password) => {
        try {
          setReady(false);
          await signInWithEmailAndPassword(auth, email, password);
        } finally {
          //setReady(true);
        }
      },
      signup: async (email, password) => {
        try {
          setReady(false);
          await createUserWithEmailAndPassword(auth, email, password);
        } finally {
          //setReady(true);
        }
      },
      signInWith: async (providerName) => {
        try {
          setReady(false);
          await signInWithPopup(auth, new OAuthProvider(providerName));
        } finally {
          //setReady(true);
        }
      },
      impersonate: async (token) => {
        try {
          setReady(false);
          await signInWithCustomToken(auth, token);
        } finally {
          setReady(true);
        }
      },
      resetPassword: async (email) => {
        try {
          setReady(false);
          await sendPasswordResetEmail(auth, email);
        } finally {
          //setReady(true);
        }
      },
      logout: async () => {
        await signOut(auth);
        setUser(null);
        setCustomData(null);
        return;
      },
    }),
    []
  );

  return useMemo(
    () => (
      <AuthContext.Provider value={{ user, error, customData, ...actions }}>
        {ready && children}
        {!ready && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              position: "fixed",
              width: "100%",
              zIndex: 1,
              top: 0,
              left: 0,
            }}
          >
            <CircularProgress />
          </Grid>
        )}
      </AuthContext.Provider>
    ),
    [actions, children, user, customData, error, ready]
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};

export default useAuth;
