import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export default function Logout() {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, []);
  return <Navigate to="/security/login" />;
}
