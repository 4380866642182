import { firestore } from "../firebaseConfig";
import extend from "./_extendJson";

/**
 *
 * @class
 * @param {Array} data
 */
function Submission(data) {
  /**
   * @returns {string}
   */
  this.getId = () => data.id;
  /**
   * @params {string}
   */
  this.setId = (val) => (data.id = val);

  /**
   * @returns {string}
   */
  this.getCode = () => data.code;
  /**
   * @params {string}
   */
  this.setCode = (val) => (data.code = val);

  /**
   * @returns {string}
   */
  this.getOwner = () => data.owner;
  /**
   * @params {string}
   */
  this.setOwner = (val) => (data.owner = val);

  /**
   * @returns {state}
   */
  this.getState = () => {
    if (data.state && data.state === "succeed") {
      if (data.output.results.length === 1) {
        return "failed";
      }
    }
    return data.state ?? (this.isExpired() ? "expired" : "processing");
  };
  /**
   * @params {state}
   */
  this.setState = (val) => (data.state = val);

  /**
   * @returns {Date}
   */
  this.getSubmittedAt = () => data.submittedAt?.toDate();
  /**
   * @params {Date}
   */
  this.setSubmittedAt = (val) =>
    (data.submittedAt = firestore.Timestamp.fromDate(val));

  /**
   * @returns {Date=}
   */
  this.getProcessedAt = () => data.processedAt?.toDate();
  /**
   * @params {Date=} val
   */
  this.setProcessedAt = (val) =>
    (data.processedAt = firestore.Timestamp.fromDate(val));

  this.isExpired = () => {
    /** Expiration Time 30 min */
    const date = new Date();
    date.setMinutes(date.getMinutes() - 30);

    return !this.getProcessedAt() && this.getSubmittedAt() < date;
  };
}

/**
 * @class
 */
const extended = extend(Submission);

export default extended;
