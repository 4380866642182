import { useState } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import Tabs, { Tab } from "../Tabs/Tabs";
import Box from "@mui/material/Box";

function LanguageIcon({ language }) {
  return (
    <Box component="span" sx={{ ...classes.lngIcon, ...classes[language] }}>
      {language === "json" ? "{ }" : language.toUpperCase()}
    </Box>
  );
}

function NewFileModal({ open, onClose, onSubmit, files }) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const handleDone = () => {
    setValue("");
    setError(null);
    onClose();
  };
  const handleSubmit = () => {
    if (files.includes(value)) {
      setError("Fichier déjà existant");
    } else if (!value.match(/^.*\.(js(on)?|php)$/)) {
      setError("Extension invalide (json, js, php attendu)");
    } else {
      onSubmit(value);
      handleDone();
    }
  };
  return (
    <Dialog open={open} onClose={handleDone}>
      <DialogTitle>Nom du fichier</DialogTitle>
      <DialogContent>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoFocus
          margin="dense"
          label="Nom du fichier"
          fullWidth
          helperText={error}
          error={Boolean(error)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDone} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const classes = {
  lngIcon: {
    marginRight: "5px",
    fontSize: "0.7em",
    fontWeight: 700,
  },
  js: {
    color: "#cbcb41",
  },
  json: {
    color: "#c7c741",
    fontSize: "0.8em",
  },
  php: {
    color: "#7a86b8",
  },
};

export default function FileToolbar({
  files,
  selectedFile,
  onSelect,
  onNew,
  onUpdate,
  onDelete,
  readOnly,
}) {
  const [openModal, setOpenModal] = useState(false);
  //const [editMode, setEditMode] = useState(false);
  //const [tempFilename, setTempFilename] = useState(false);
  const handleNew = (e) => {
    setOpenModal(true);
  };

  //const handleUpdate = (oldValue) => (e) => {
  //  if (e.key === "Enter") {
  //    onUpdate(oldValue, tempFilename);
  //    onSelect(tempFilename);
  //    setEditMode(false);
  //  }
  //};

  return (
    <>
      <Tabs>
        {files.map((file, index) => (
          <Tab
            key={file}
            selected={selectedFile === file}
            style={{
              "&:hover": {
                "& span:last-child": {
                  visibility: "visible",
                  fontSize: "0.9rem",
                  marginLeft: "5px",
                  color: "gainsboro",
                },
              },
              "& span:last-child": {
                visibility: "hidden",
                fontSize: "0.9rem",
                marginLeft: "5px",
                color: "gainsboro",
              },
            }}
            onClick={() => onSelect(file)}
          >
            <LanguageIcon language={file.split(".").pop()} />
            <span>{file}</span>
            <span
              style={files.length === 1 ? { visibility: "hidden" } : {}}
              onClick={() => onDelete(file)}
            >
              x
            </span>
          </Tab>
        ))}
        {!readOnly && (
          <IconButton size="small" onClick={handleNew}>
            <AddIcon style={{ color: "#787777" }}></AddIcon>
          </IconButton>
        )}
      </Tabs>
      <NewFileModal
        open={openModal}
        files={files}
        onClose={() => setOpenModal(false)}
        onSubmit={onNew}
      />
    </>
  );
}
