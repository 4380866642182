import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { useMemo } from "react";
import { functions } from "../../../firebaseConfig";
import { useFirebaseCollection } from "../../../hooks";
import useCancellablePromise, {
  CancelledPromiseError,
} from "../../../hooks/useCancellablePromise";
import Autocomplete from "@mui/material/Autocomplete";
import { orderBy } from "firebase/firestore";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

export default function UserFilters({
  value,
  onChange,
  onReady,
  readOnly,
  onUsersLoaded,
}) {
  const { cancellablePromise } = useCancellablePromise();
  const [users, setUsers] = useState({});
  const checkGroups = useMemo(() => [orderBy("name", "asc")], []);
  const { data: groups } = useFirebaseCollection("groups", checkGroups);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const listUsers = httpsCallable(functions, "listUsers");
    cancellablePromise(listUsers())
      .then(
        (results) =>
          setUsers(results.data) ||
          (onUsersLoaded && onUsersLoaded(results.data)) ||
          onReady()
      )
      .catch((e) =>
        e instanceof CancelledPromiseError
          ? undefined
          : enqueueSnackbar(e.message, { variant: "danger" })
      );
  }, []);

  useEffect(() => {
    value.groups.length && onChange({ ...value, users: [] });
  }, [value.groups]);

  return (
    <Grid item container justifyContent="space-around" spacing={2}>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={readOnly}
          multiple
          fullWidth
          options={groups.sort((a, b) => a.name.localeCompare(b.name))}
          value={value.groups}
          onChange={(_, newVal) => {
            onChange({
              ...value,
              groups: newVal,
            });
          }}
          isOptionEqualToValue={(option, value) => value.id === option.id}
          getOptionLabel={(option) => console.log(option) || option.name}
          renderInput={(params) => (
            <TextField {...params} label="Group" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={readOnly}
          multiple
          fullWidth
          options={Object.values(users)
            .filter(
              (u) =>
                !value.groups.length ||
                u.groups?.find?.((g) =>
                  value.groups.find((gg) => gg.id === g.id)
                )
            )
            .sort((a, b) => {
              const adomain = a.email.split("@")[1] ?? "unknown",
                bdomain = b.email.split("@")[1] ?? "unknown";
              return -bdomain.localeCompare(adomain) !== 0
                ? -bdomain.localeCompare(adomain)
                : -b.email.localeCompare(a.email);
            })}
          isOptionEqualToValue={(option, value) => value.id === option.id}
          value={value.users}
          onChange={(_, newVal) => {
            onChange({
              ...value,
              users: newVal,
            });
          }}
          groupBy={(option) => option.email.split("@")[1] ?? "unknown"}
          getOptionLabel={(option) => option.email}
          renderInput={(params) => (
            <TextField {...params} label="Users" variant="outlined" />
          )}
        />
      </Grid>
    </Grid>
  );
}
