import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useMemo, useRef } from "react";
import { ConsoleProvider } from "../../../hooks/functional/useConsole";
import useExercisesGroup from "../../../hooks/functional/useExercisesGroup";
import { SubmissionProvider } from "../../../hooks/functional/useSubmissions";
import useAuth from "../../../hooks/useAuth";
import useQuery from "../../../hooks/useQuery";
import BottomPanel from "./Panel/BottomPanel";
import PlaygroundPanel from "./Panel/PlaygroundPanel";
import VerticalPanels from "./Panel/VerticalPanels";
import hotkeys from "hotkeys-js";

export default function Playground() {
  const playgroundPanelRef = useRef();
  const { user } = useAuth();
  const params = useQuery();
  const categoryId = params.get("category");
  const exo = params.get("exercise");
  const { getCategory, loading: exoLoading } = useExercisesGroup();

  useEffect(() => {
    if (playgroundPanelRef.current) {
      const onFullScreenShortcut = (e) => {
        e.preventDefault();
        playgroundPanelRef.current.requestFullscreen();
        return false;
      };
      hotkeys("ctrl+shift+f,command+shift+f", onFullScreenShortcut);
      return () =>
        hotkeys.unbind("ctrl+shift+f,command+shift+f", onFullScreenShortcut);
    }
  }, [playgroundPanelRef.current]);

  const category = useMemo(
    () => getCategory(categoryId),
    [categoryId, exoLoading]
  );
  const exercise = useMemo(
    () => (exo ? category?.exercices[exo] : undefined),
    [category, exo]
  );

  const filters = useMemo(() => {
    if (!category) return null;
    const base = { owner: { operator: "==", value: user.uid } };
    if (category) {
      base["exercise.category"] = {
        operator: "==",
        value: category.category_name,
      };
      base["exercise.name"] = {
        operator: "in",
        value: [exercise.function, exercise.id],
      };
    }
    return base;
  }, [category?.id, exercise?.id, user.uid]);

  return useMemo(
    () =>
      exoLoading ? (
        <CircularProgress />
      ) : (
        <Grid
          container
          wrap="nowrap"
          ref={playgroundPanelRef}
          sx={{
            "&::backdrop": {
              backgroundColor: "inherit",
            },
          }}
        >
          <Grid item>
            <VerticalPanels />
          </Grid>
          <ConsoleProvider>
            <Grid item xs container direction={"column"} wrap="nowrap">
              <SubmissionProvider filters={filters} itemsPerPage={10}>
                <Grid item xs>
                  <PlaygroundPanel />
                </Grid>

                <BottomPanel />
              </SubmissionProvider>
            </Grid>
          </ConsoleProvider>
        </Grid>
      ),
    [exoLoading, category, exercise, filters]
  );
}
