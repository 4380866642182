import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../../firebaseConfig";
import useAuth from "../useAuth";

const extensionsMapping = {
  js: "javascript",
  json: "json",
  php: "php",
};

export default function useSnippets(active) {
  const [snippets, setSnippets] = useState(null);
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    if (active && snippets === null) {
      setLoading(true);
      const docRef = doc(firestore, "user-submissions-success", user.uid);
      onSnapshot(docRef, (snapshot) => {
        const result = {};
        const data = snapshot.data()?.exercises || {};
        Object.keys(data)
          .sort((a, b) => a.localeCompare(b))
          .forEach((group) => {
            Object.values(data[group])
              .sort((a, b) => a.name.localeCompare(b.name))
              .forEach((value) => {
                const language =
                  typeof value.code === "string"
                    ? "js"
                    : Object.keys(value.code)[0].match(/\.([a-z]+)$/)[1];
                if (!result[extensionsMapping[language]])
                  result[extensionsMapping[language]] = [];

                result[extensionsMapping[language]].push({
                  title: `Snippet ${group}-${value.name}`,
                  description: `${group} ${value.name}`,
                  code: Object.values(value.code)[0],
                });
              });
          });
        setSnippets(result);
        if (loading) setLoading(false);
      });
    }
  }, [active]);

  return { snippets: snippets || [], loading };
}
