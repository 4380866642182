import React, { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const PrivateRoute = ({ element: Component, asAdmin = false, ...rest }) => {
  const { user, customData } = useAuth();
  let location = useLocation();
  const element = useMemo(() => {
    if (!Boolean(user)) {
      return (
        <Navigate to={"/security/login"} state={{ from: location }} replace />
      );
    } else if (asAdmin && customData && !customData.isAdmin) {
      return <Navigate to={"/"} replace />;
    }
    return <Component />;
  }, [user, customData, asAdmin, location]);

  return element;
};

export default PrivateRoute;
