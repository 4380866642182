import { TreeView, TreeItem } from "@mui/x-tree-view";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "../../../../../hooks/useQuery";
import PanelLayout from "../PanelLayout";
import CheckIcon from "@mui/icons-material/Check";
import useExercisesGroup from "../../../../../hooks/functional/useExercisesGroup";
import useSubmissionsSuccess from "../../../../../hooks/functional/useSubmissionsSuccess";
import { Box } from "@mui/material";

export default function GroupPanel(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { getCategory, loading } = useExercisesGroup();
  const params = useQuery();
  const { exosSucceed } = useSubmissionsSuccess(true);
  const categoryId = params.get("category");
  const exo = params.get("exercise");
  const [selected, setSelected] = useState(`${categoryId}**${exo}`);

  useEffect(() => {
    setSelected(`${categoryId}**${exo}`);
  }, [categoryId, exo]);

  const category = useMemo(() => {
    return getCategory(categoryId);
  }, [categoryId]);

  const handleSelect = useCallback(
    (e, nodeIds) => {
      if (!nodeIds.includes("**")) return;
      setSelected(nodeIds);
    },
    [selected]
  );

  useEffect(() => {
    if (selected.length) {
      const queryParams = selected.split("**");
      navigate({
        pathname: location.pathname,
        search:
          "?" +
          new URLSearchParams({
            category: queryParams[0],
            exercise: queryParams[1],
          }).toString(),
      });
    }
  }, [selected]);

  return useMemo(
    () => (
      <PanelLayout
        title={"Current: " + category?.category_name || "Group Exercises"}
        {...props}
        loading={loading}
      >
        <TreeView onNodeSelect={handleSelect} selected={selected}>
          {Object.entries(category.exercices)
            .sort((a, b) => a[1].name.localeCompare(b[1].name))
            .map(([key, value]) => (
              <TreeItem
                key={key}
                nodeId={`${category.id}**${key}`}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ minWidth: "24px", mr: 1 }}>
                      {key in (exosSucceed[category?.category_name] ?? {}) && (
                        <CheckIcon style={{ color: "green" }} />
                      )}
                    </Box>
                    {value.name}
                  </Box>
                }
              />
            ))}
        </TreeView>
      </PanelLayout>
    ),
    [category, loading, selected, props, exosSucceed]
  );
}
