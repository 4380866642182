import Box from "@mui/material/Box";

export default function Container({ children }) {
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(10),
        paddingBottom: (theme) => theme.spacing(2),
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
}
