import { useMemo } from "react";

export default function useUrlGenerator() {
  const generateUrl = useMemo(
    () =>
      ({ searchParams, pathname }) => {
        let params = "";
        if (searchParams) {
          params = "?" + new URLSearchParams(searchParams).toString();
        }
        return `${window.location.protocol}//${window.location.host}${pathname}${params}`;
      },
    []
  );
  return generateUrl;
}
