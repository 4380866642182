import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import * as Themes from "react-syntax-highlighter/dist/esm/styles/prism";

export default function Markdown({
  theme = "vscDarkPlus",
  components = {},
  ...props
}) {
  components = {
    ...components,
    em({ node, ...props }) {
      return <i style={{ color: "red" }} {...props} />;
    },
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          children={String(children).trim()}
          language={match[1]}
          style={Themes[theme]}
          PreTag={(props) => (
            <span {...props} style={{ ...props.style, display: "block" }} />
          )}
          showLineNumbers
          {...props}
        />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      components={components}
      {...props}
    />
  );
}
