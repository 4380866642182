import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import RestartAllIcon from "@mui/icons-material/RestartAlt";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BackupIcon from "@mui/icons-material/Backup";
import Grid from "@mui/material/Grid";
import { Hook, Decode, Unhook } from "console-feed";
import SimpleFileEditor from "../../lib/Editor/SimpleFileEditor";
import MultiFileEditor from "../../lib/Editor/MultiFileEditor";
import useAuth from "../../../hooks/useAuth";
import useSnippets from "../../../hooks/functional/useSnippets";
import { Tooltip } from "@mui/material";
import useConsole from "../../../hooks/functional/useConsole";
import useThrottling from "../../../hooks/useThrottling";
import InfoIcon from "@mui/icons-material/Info";
import PlaygroundHelp from "./PlaygroundHelp";

function fetchSavedData() {
  const rawData = localStorage.getItem("autosave-editor");
  return (rawData && JSON.parse(rawData)) || { states: {} };
}

function getSavedData(saveKey) {
  const data = fetchSavedData();
  if (data.states[saveKey]) return data.states[saveKey].item;
  return null;
}
function setSavedData(saveKey, item) {
  const data = fetchSavedData();
  if (JSON.stringify(data.states?.[saveKey]?.item) === JSON.stringify(item))
    return;
  data.states[saveKey] = {
    item,
    date: Date.now(),
  };
  localStorage.setItem("autosave-editor", JSON.stringify(data));
}

export default function Playground({
  value,
  onChange,
  onSubmit,
  isMultiFiles = false,
  isLocallyExecutable = true,
  isSnippetsAllowed = true,
  isSubmittable = true,
  isSubmittableMessage = null,
  autoSave = false,
  saveKey = null,
}) {
  const [code, setCode] = useState(value);
  const {
    customData: { preferredTheme = "monokai" },
  } = useAuth();
  const [selectedFile, setSelectedFile] = useState("index.js");
  const { setLogs } = useConsole();
  const { snippets } = useSnippets(isSnippetsAllowed);
  const throttle = useThrottling(700);
  const [openHelp, setOpenHelp] = useState(false);

  useEffect(() => {
    value && setCode(value);
  }, [value]);

  useEffect(() => {
    onChange && onChange(code);
  }, [code]);

  const BaseEditor = useMemo(
    () => (isMultiFiles ? MultiFileEditor : SimpleFileEditor),
    [isMultiFiles]
  );

  useEffect(() => {
    if (!autoSave) return;
    if (getSavedData(saveKey) !== null) {
      setCode(getSavedData(saveKey));
      setSelectedFile(Object.keys(getSavedData(saveKey))[0]);
    }
  }, [autoSave, saveKey]);

  useEffect(() => {
    if (autoSave) {
      throttle(() => setSavedData(saveKey, code));
    }
  }, [code, saveKey, autoSave, throttle]);

  const _onSubmit = () => {
    onSubmit(code);
  };
  const canExecute = useMemo(() => {
    return (
      isLocallyExecutable &&
      Object.keys(code).includes("index.js") &&
      code["index.js"].trim() !== ""
    );
  }, [code, isLocallyExecutable]);

  const canSubmit = useMemo(() => {
    return (
      isSubmittable &&
      Object.keys(code).length > 0 &&
      Object.values(code).find((v) => v.trim() !== "")
    );
  }, [code, isSubmittable]);

  const _execLocal = () => {
    if (code[selectedFile].indexOf("console.") === -1) {
      alert(
        "You need to use a console method, don't forget to call your function."
      );
      return;
    }
    setLogs([]);
    Hook(window.console, (log) => {
      setLogs((logs) => [...logs, Decode(log)]);
    });
    try {
      Function(code["index.js"])();
    } catch (e) {
      console.error(e);
    }
    Unhook(window.console);
  };

  useEffect(() => {
    //Add shortcuts
    const handleKeyDown = (e) => {
      if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        _onSubmit();
      }
      if (isLocallyExecutable && e.key === "r" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        _execLocal();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [_execLocal, _onSubmit]);

  const Editor = useMemo(
    () => (
      <BaseEditor
        code={code}
        theme={preferredTheme}
        onChange={(value) => {
          setCode(value);
          //setSavedData(saveKey, value);
        }}
        customCommands={
          [
            //{
            //  name: "submit-on-save",
            //  keyBinding: [KeyMod.CtrlCmd | KeyCode.KeyS],
            //  handler: _onSubmit,
            //},
            //{
            //  name: "run-on-save",
            //  keyBinding: [KeyMod.CtrlCmd | KeyCode.KeyR],
            //  handler: _execLocal,
            //},
          ]
        }
        snippets={snippets}
      />
    ),
    [
      code,
      selectedFile,
      preferredTheme,
      isSnippetsAllowed,
      snippets,
      saveKey,
      _execLocal,
      _onSubmit,
    ]
  );

  return (
    <Grid container direction={"column"} sx={{ height: "100%" }} wrap="nowrap">
      <Grid
        id="playground"
        item
        container
        direction={"column"}
        wrap="nowrap"
        xs
      >
        {Editor}
      </Grid>
      <Grid item container sx={{ px: 2, py: 1 }} alignItems="center">
        <Grid item container spacing={2} xs>
          {canExecute && (
            <Grid item>
              <Tooltip title="Cmd/Ctrl + R" placement="top">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={_execLocal}
                  startIcon={<PlayArrowIcon />}
                >
                  Run locally
                </Button>
              </Tooltip>
            </Grid>
          )}
          {(canSubmit || (!canSubmit && isSubmittableMessage)) && (
            <Grid item>
              <Tooltip
                title={canSubmit ? "Cmd/Ctrl + S" : isSubmittableMessage}
                placement="top"
              >
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={_onSubmit}
                    startIcon={<BackupIcon />}
                    disabled={!canSubmit}
                  >
                    Submit
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <Tooltip title="Reset Code" placement="top">
              <Button
                aria-label="reset"
                variant="outlined"
                color="primary"
                onClick={() =>
                  setCode(
                    typeof value === "string" ? { "index.js": value } : value
                  )
                }
                startIcon={<RestartAllIcon />}
              >
                Reset
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item>
          <Tooltip title="Astuces" placement="top">
            <Button
              aria-label="reset"
              variant="outlined"
              color="primary"
              onClick={() => setOpenHelp(true)}
              startIcon={<InfoIcon />}
            >
              Aide
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <PlaygroundHelp open={openHelp} onClose={() => setOpenHelp(false)} />
    </Grid>
  );
}
