import React, { useEffect, useMemo, useState } from "react";
import { firestore as db } from "../../../firebaseConfig";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import ExercisesGroupSelector from "./ExercisesGroupSelector";
import DatasetForm from "./DatasetForm";
import useAuth from "../../../hooks/useAuth";
import { useFirebaseCollection } from "../../../hooks";
import {
  addDoc,
  collection,
  orderBy,
  updateDoc,
  doc,
} from "@firebase/firestore";
import useLayoutContext from "../../../hooks/useLayoutContext";

export default function DatasetsView() {
  useLayoutContext({ title: "Datasets" });
  const [exercisesGroupSelected, setExercisesGroupSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const checkDataset = useMemo(
    () => [orderBy("category"), orderBy("name")],
    []
  );
  const { data: datasets } = useFirebaseCollection(
    "exercise-dataset",
    checkDataset
  );

  const checkExercises = useMemo(() => [orderBy("category_name")], []);
  const { data: exercisesGroups } = useFirebaseCollection(
    "exercises",
    checkExercises
  );

  useEffect(() => {
    if (exercisesGroups.length > 0 && exercisesGroupSelected.length === 0) {
      const firstGroup = exercisesGroups[0];
      const firstExo = Object.keys(firstGroup.exercices).sort()[0];
      setExercisesGroupSelected([firstGroup.category_name, firstExo]);
    }
  }, [exercisesGroups, exercisesGroupSelected]);

  const _onSubmit = async (values) => {
    const { id, ...data } = values;
    if (values) {
      data.lastUpdated = new Date();
      data.lastUpdator = user.uid;
      if (!id) {
        await addDoc(collection(db, "exercise-dataset"), data);
        enqueueSnackbar("Dataset submitted !", { variant: "success" });
      } else {
        await updateDoc(doc(db, "exercise-dataset", id), data);
        enqueueSnackbar("Dataset updated !", { variant: "success" });
      }
    }
  };

  const dataset = useMemo(() => {
    const [category, name] = exercisesGroupSelected;
    return (
      datasets.find((dt) => {
        return dt.category === category && dt.name === name;
      }) || { category, name }
    );
  }, [datasets, exercisesGroupSelected]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={3} md={2}>
        <ExercisesGroupSelector
          onSelect={setExercisesGroupSelected}
          selectedValue={exercisesGroupSelected}
          exercisesGroups={exercisesGroups}
        />
      </Grid>
      <Grid item xs={12} sm={9} md={10} container direction={"column"}>
        {exercisesGroupSelected.length > 1 && (
          <DatasetForm
            dataset={dataset}
            datasets={datasets}
            onSave={_onSubmit}
          />
        )}
      </Grid>
    </Grid>
  );
}
