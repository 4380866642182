import { Avatar, Badge, CircularProgress } from "@mui/material";
import { useMemo } from "react";
import useSubmissions from "../../../../../hooks/functional/useSubmissions";
import Timelapse from "@mui/icons-material/Timelapse";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import { blue, red, green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

const StyledAvatar = styled(Avatar)({
  width: "1rem",
  height: "1rem",
  border: "none",
  fontSize: "1rem",
  marginLeft: -8,
});

export default function SubmissionHeader() {
  const { submissions, loading } = useSubmissions();

  const submissionSymbol = useMemo(() => {
    if (loading)
      return (
        <StyledAvatar sx={{ bgcolor: "white" }}>
          <CircularProgress size="0.8rem" />
        </StyledAvatar>
      );
    if (!submissions.length) return false;
    const submission = submissions[0];
    if (submission.state === "processing")
      return (
        <StyledAvatar sx={{ bgcolor: blue[500] }}>
          <Timelapse sx={{ fontSize: "0.8rem" }} />
        </StyledAvatar>
      );
    if (submission.state === "succeed")
      return (
        <StyledAvatar sx={{ bgcolor: green[500] }}>
          <Check sx={{ fontSize: "0.8rem" }} />
        </StyledAvatar>
      );
    if (["failed", "expired"].includes(submission.state))
      return (
        <StyledAvatar sx={{ bgcolor: red[500] }}>
          <Close sx={{ fontSize: "0.8rem" }} />
        </StyledAvatar>
      );
  }, [submissions, loading]);

  return <Badge badgeContent={submissionSymbol}>Submissions</Badge>;
}
