import React, { useMemo } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Controller, useForm, useWatch } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import useFirebaseCollection from "../../../../../hooks/useFirebaseCollection";
import { orderBy } from "@firebase/firestore";

export default ({ defaultValues, onSave, onClose }) => {
  const checkGroups = useMemo(() => [orderBy("name", "asc")], []);
  const { data: groups } = useFirebaseCollection("groups", checkGroups);
  const { handleSubmit, register, reset, control } = useForm({
    defaultValues,
  });
  const timeLimited = useWatch({ control, name: "timeLimited" });

  const onSubmit = (values) => {
    if (defaultValues?.id) {
      values.id = defaultValues.id;
    }
    if (!values.timeLimited) {
      delete values.dateStart;
      delete values.dateEnd;
    }
    onSave(values).then(() => reset());
  };

  //const dateStartRef = register("dateStart", { valueAsDate: true });
  const dateEndRef = register("dateEnd", { valueAsDate: true });
  const { ref: timeLimitedRegisterRef, ...timeLimitedRegister } =
    register("timeLimited");

  return (
    <Grid container direction="column" justifyContent="center" spacing={2}>
      <Grid container item justifyContent="space-around">
        <Grid item xs={9} md={6}>
          <Controller
            render={({ field: { onChange, ...props } }) => {
              return (
                <Autocomplete
                  fullWidth
                  options={groups}
                  getOptionLabel={(opt) => opt.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Group" margin="normal" />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              );
            }}
            onChange={([, data]) => data}
            defaultValue={defaultValues?.group}
            name="group"
            control={control}
          />
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              inputRef={timeLimitedRegisterRef}
              {...timeLimitedRegister}
            />
          }
          label="Time limited"
        />
      </Grid>
      {timeLimited && (
        <Grid container item justifyContent="space-around" spacing={2}>
          <Grid item xs>
            <TextField
              inputProps={register("dateStart", { valueAsDate: true })}
              id="datetime-local"
              label="Start Date"
              type="datetime-local"
              name="dateStart"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs>
            <TextField
              inputRef={dateEndRef.ref}
              id="datetime-local"
              label="End Date"
              type="datetime-local"
              inputProps={register("dateEnd", { valueAsDate: true })}
              name="dateEnd"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      <Grid item container justifyContent={"flex-end"}>
        <Button onClick={onClose}>Close</Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
