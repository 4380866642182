export default function extend(Base) {
  return function (data = []) {
    const base = new Base(data);
    return new Proxy(base, {
      getPrototypeOf: (target) => Base.prototype,
      get: function (obj, prop) {
        if (obj.hasOwnProperty(prop)) return Reflect.get(obj, prop);
        if (typeof prop === "string") {
          const getter = "get" + prop.charAt(0).toUpperCase() + prop.slice(1);
          if (typeof obj[getter] === "function") return obj[getter]();
        }
        return data[prop];
      },
      getOwnPropertyDescriptor(target, key) {
        return {
          value: this.get(target, key),
          enumerable: true,
          configurable: true,
        };
      },
      ownKeys: function () {
        return Object.keys(data);
      },
    });
  };
}
