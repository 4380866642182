import { forwardRef, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, createTheme, ThemeProvider, Typography } from "@mui/material";
import Tabs, { Tab } from "../../../lib/Tabs/Tabs";
import { Console } from "console-feed";
import useConsole from "../../../../hooks/functional/useConsole";
import ConsoleHeader from "./BottomPanel/ConsoleHeader";
import SubmissionHeader from "./BottomPanel/SubmissionHeader";
import SubmissionPanel from "./SubmissionPanel";
import { blue, grey } from "@mui/material/colors";
import { ResizableBox } from "react-resizable";
import useMainSize from "../../../../hooks/useMainSize";

const theme = createTheme({
  palette: {
    primary: {
      main: grey[50],
    },
    secondary: {
      main: grey[100],
    },
    background: {
      default: "#252526",
    },
  },
  components: {
    // Name of the component
    MuiListItem: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "white",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: grey[50],
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        // Name of the slot
        secondary: {
          // Some CSS
          color: grey[100],
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        // Name of the slot
        wrapper: {
          // Some CSS
          height: "100%",
        },
        root: {
          // Some CSS
          height: "100%",
        },
      },
    },
  },
});
const Wrapper = styled(ResizableBox)(({ theme }) => ({
  backgroundColor: grey[100],
  borderTopColor: grey[300],
  borderTopWidth: 2,
  overflow: "hidden",
}));
const StyledHandle = styled("div")(({ theme }) => ({
  width: "100%",
  height: 2,
  position: "absolute",
  right: 0,
  top: 0,
  backgroundColor: "transparent",
  "&:hover": {
    cursor: "ns-resize",
    height: 4,
    backgroundColor: blue[500],
  },
}));
const CustomResizeHandle = forwardRef((props, ref) => {
  const { handleAxis, ...restProps } = props;
  return (
    <StyledHandle
      className={`custom-handle custom-handle-${handleAxis} custom-resize-handle-component`}
      ref={ref}
      {...restProps}
    ></StyledHandle>
  );
});

const BottomBar = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
}));
const Content = styled(Box, { shouldForwardProp: (prop) => prop !== "in" })(
  ({ in: open }) => ({
    width: "100%",
    flex: "1",
    overflow: "auto",
  })
);

function Headers({ onSelect, activePanel }) {
  return (
    <Tabs
      style={{
        height: 50,
        maxHeight: 50,
        "& .MuiBadge-root": {
          paddingRight: 1.5,
          "& .MuiBadge-badge": { right: 2 },
        },
      }}
    >
      <Tab
        onClick={() => onSelect("console")}
        selected={activePanel === "console"}
      >
        <ConsoleHeader />
      </Tab>
      <Tab
        onClick={() => onSelect("submission")}
        selected={activePanel === "submission"}
      >
        <SubmissionHeader />
      </Tab>
    </Tabs>
  );
}

export default function BottomPanel() {
  const [activePanel, setActivePanel] = useState("console");
  const { logs } = useConsole();
  const [height, setHeight] = useState(150);
  const debouceResize = useRef(null);

  function handleSelect(title) {
    setActivePanel(title === activePanel ? null : title);
  }

  const onResize = (_, data) => {
    if (debouceResize.current) clearTimeout(debouceResize.current);
    debouceResize.current = setTimeout(() => {
      const newSize = data.size;
      const newHeight = newSize.height;
      const dir = newHeight < height ? "bottom" : "top";
      if (dir === "bottom" && newHeight < 50) {
        setHeight(50);
      } else {
        setHeight(newHeight);
      }
    }, 100);
  };

  const { size } = useMainSize();

  return (
    <ThemeProvider theme={theme}>
      <Wrapper
        height={height}
        width={Infinity}
        resizeHandles={["n"]}
        handle={<CustomResizeHandle />}
        minConstraints={[0, 50]}
        onResize={onResize}
        maxConstraints={[Infinity, (size.clientHeight * 66) / 100]}
      >
        <BottomBar>
          <Headers onSelect={handleSelect} activePanel={activePanel} />
          <Content in={!!activePanel}>
            {activePanel === "console" &&
              ((logs.length > 0 && <Console logs={logs} variant="dark" />) || (
                <Typography m={2} color={"primary"}>
                  No logs available
                </Typography>
              ))}
            {activePanel === "submission" && <SubmissionPanel />}
          </Content>
        </BottomBar>
      </Wrapper>
    </ThemeProvider>
  );
}
