import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Person from "@mui/icons-material/Person";
import Assignment from "@mui/icons-material/Assignment";
import AssignmentTurnedIn from "@mui/icons-material/AssignmentTurnedIn";
import Group from "@mui/icons-material/Group";
import PlaylistAddCheck from "@mui/icons-material/PlaylistAddCheck";
import Comment from "@mui/icons-material/Comment";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const drawerWidth = 240;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? drawerWidth : theme.spacing(7),
  flexShrink: 0,
  whiteSpace: "nowrap",
  position: "relative",
  height: "100%",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    width: open ? drawerWidth : theme.spacing(7),
  },
  "& .MuiDrawer-paper": {
    width: open ? drawerWidth : theme.spacing(7),
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative",
    height: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const routes = [
  { to: "/admin/chat", label: "Chat", icon: <Comment /> },
  { to: "/admin/users", label: "Users", icon: <Person /> },
  { to: "/admin/groups", label: "Groups", icon: <Group /> },
  { to: "/admin/exercises", label: "Exercises", icon: <Assignment /> },
  { to: "/admin/datasets", label: "Datasets", icon: <AssignmentTurnedIn /> },
  {
    to: "/admin/submissions",
    label: "Submissions",
    icon: <PlaylistAddCheck />,
  },
];

const LinkRouter = React.forwardRef((props, ref) => (
  <Link {...props} ref={ref} component={RouterLink} />
));

export default function AdminMenu() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <StyledBox>
        <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </StyledBox>
      <Divider />
      <List>
        {routes.map((route, index) => (
          <ListItem button key={route.to} component={LinkRouter} to={route.to}>
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText primary={route.label} />
          </ListItem>
        ))}
      </List>
    </StyledDrawer>
  );
}
