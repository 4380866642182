import React, { useMemo } from "react";

import { Navigate, Link, useLocation, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ProviderLogin from "./ProviderLogin";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Grid from "@mui/material/Grid";
import GlobalStyles from "@mui/material/GlobalStyles";

const Paper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
const ThemedAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

export default function SecurityLayout() {
  const { user } = useAuth();
  const { state, pathname } = useLocation();
  const theme = useTheme();

  const title = useMemo(() => {
    if (pathname === "/security/login") {
      return "Login";
    } else if (pathname === "/security/register") {
      return "Register";
    } else if (pathname === "/security/reset") {
      return "Reset Password";
    }
  }, [pathname]);

  const BottomLinks = useMemo(() => {
    const links = [];
    if (pathname !== "/security/login") {
      links.push(
        <Grid item key={"login"}>
          <Link to="/security/login" variant="body2">
            {"Have an account? Sign In"}
          </Link>
        </Grid>
      );
    }
    if (pathname !== "/security/reset") {
      links.push(
        <Grid item key={"reset"}>
          <Link to="/security/reset" variant="body2">
            {"Forgot password?"}
          </Link>
        </Grid>
      );
    }
    if (pathname !== "/security/register") {
      links.push(
        <Grid item key={"register"}>
          <Link to="/security/register" variant="body2">
            {"Don't have an account? Register"}
          </Link>
        </Grid>
      );
    }
    return (
      <Grid container direction={"column"}>
        {links}
      </Grid>
    );
  }, [pathname]);

  return (
    <>
      {user && <Navigate to={(state && state.from) || "/"} />}
      {!user && (
        <Container component="main" maxWidth="xs">
          <GlobalStyles
            styles={{ body: { backgroundColor: theme.palette.common.white } }}
          />
          <CssBaseline />
          <Paper>
            <ThemedAvatar>
              <LockOutlinedIcon />
            </ThemedAvatar>
            <Typography component="h1" variant="h5">
              {title}
            </Typography>
            <Outlet />
            {BottomLinks}
            <ProviderLogin />
          </Paper>
        </Container>
      )}
    </>
  );
}
