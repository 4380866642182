import React, { useMemo, useState } from "react";
import { firestore } from "../../../firebaseConfig";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Share from "@mui/icons-material/Share";
import UpdateModal from "./UpdateModal";
import useAuth from "../../../hooks/useAuth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
} from "@firebase/firestore";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { TextField } from "@mui/material";
import Group from "@mui/icons-material/Group";
import { useNavigate } from "react-router-dom";
import useUrlGenerator from "../../../hooks/useUrlGenerator";
import useLayoutContext from "../../../hooks/useLayoutContext";

export default function GroupsView() {
  useLayoutContext({ title: "Groups" });
  const [updateModal, setUpdateModal] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const checkGroups = useMemo(() => [orderBy("name", "asc")], []);
  const { data: groups } = useFirebaseCollection("groups", checkGroups);
  const [filterValue, setFilterValue] = useState("");
  const navigate = useNavigate();
  const generateUrl = useUrlGenerator();

  const _onSubmit = async (values) => {
    const { id = false, ...data } = values;
    if (values) {
      data.lastUpdated = new Date();
      data.lastUpdator = user.uid;
      if (!id) {
        await addDoc(collection(firestore, "groups"), data);
        enqueueSnackbar("Group submitted !", { variant: "success" });
      } else {
        await updateDoc(doc(firestore, "groups", id), data);
        enqueueSnackbar("Group updated !", { variant: "success" });
      }
      setUpdateModal(undefined);
    }
  };

  const _onDelete = async (values) => {
    if (window.confirm("Are you sure you want to delete this group ?")) {
      await deleteDoc(doc(firestore, "groups", values.id));
      enqueueSnackbar("Group deleted !", { variant: "success" });
    }
  };

  const _onShare = (url) => {
    if (navigator.share) {
      navigator.share({
        title: "Group invitation",
        text: "Join this group",
        url,
      });
    } else {
      navigator.clipboard.writeText(url);
      enqueueSnackbar("URL copied to clipboard", { variant: "success" });
    }
  };

  const filteredGroups = useMemo(() => {
    const searchRegex = new RegExp(filterValue, "i");
    return groups?.filter((group) => {
      return Object.keys(group).some((field) => {
        return searchRegex.test(`${field}:${JSON.stringify(group[field])}`);
      });
    });
  }, [groups, filterValue]);

  return (
    <>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs md={6}>
          <TextField
            label="Search value..."
            value={filterValue}
            fullWidth
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Fab
            variant="extended"
            size="large"
            color="primary"
            aria-label="add"
            onClick={() => setUpdateModal({})}
          >
            <Add sx={{ mr: 1 }} />
            Add Group
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs>
        <DataGrid
          disableSelectionOnClick
          pageSize={10}
          rowsPerPageOptions={[10, 30, 50]}
          pagination
          columns={[
            { field: "id", headerName: "ID", width: 250 },
            {
              field: "name",
              headerName: "Name",
              flex: 1,
            },
            {
              field: "Actions",
              type: "actions",
              //flex: 1,
              minWidth: 130,
              getActions: (params) => [
                <GridActionsCellItem
                  icon={<Group />}
                  onClick={() =>
                    navigate({
                      pathname: "/admin/users",
                      search: "?search=" + params.row.id,
                    })
                  }
                  label="Users"
                />,
                <GridActionsCellItem
                  icon={<Share />}
                  onClick={() =>
                    _onShare(
                      generateUrl({
                        pathname: "/profile",
                        searchParams: {
                          invitation: params.row.id,
                        },
                      })
                    )
                  }
                  label="Share"
                />,
                <GridActionsCellItem
                  icon={<Edit />}
                  onClick={() => setUpdateModal(params.row)}
                  label="Update"
                  showInMenu
                />,
                <GridActionsCellItem
                  icon={<Delete />}
                  onClick={() => _onDelete(params.row)}
                  label="Delete"
                  showInMenu
                />,
              ],
            },
          ]}
          rows={filteredGroups}
        />
      </Grid>
      <UpdateModal
        group={updateModal}
        onSubmit={_onSubmit}
        onClose={() => setUpdateModal(undefined)}
      />
    </>
  );
}
