import { forwardRef, useEffect, useState } from "react";
import ExercisesPanel from "./VerticalPanel/ExercicesPanel";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { useTheme, styled } from "@mui/material/styles";
import useMainSize from "../../../../hooks/useMainSize";
import { grey, blue } from "@mui/material/colors";
import GroupPanel from "./VerticalPanel/GroupPanel";
import RulePanel from "./VerticalPanel/RulePanel";

const panelsData = [RulePanel, GroupPanel, ExercisesPanel];

const StyledPanel = styled(ResizableBox)(({ theme }) => ({
  backgroundColor: grey[100],
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderRightColor: grey[300],
  borderRightWidth: 2,
  borderRightStyle: "solid",
  overflow: "scroll",
}));

const StyledHandle = styled("div")(({ theme }) => ({
  width: 2,
  height: "100%",
  position: "absolute",
  right: 0,
  top: 0,
  backgroundColor: "transparent",
  "&:hover": {
    cursor: "ew-resize",
    width: 4,
    backgroundColor: blue[500],
  },
}));

const CustomResizeHandle = forwardRef((props, ref) => {
  const { handleAxis, ...restProps } = props;
  return (
    <StyledHandle
      className={`custom-handle custom-handle-${handleAxis} custom-resize-handle-component`}
      ref={ref}
      {...restProps}
    ></StyledHandle>
  );
});

function computeMaxHeight(margin) {
  return (
    Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ) - margin
  );
}

export default function VerticalPanels({ panels = panelsData }) {
  const theme = useTheme();
  const [activePanel, setActivePanel] = useState([0]);
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(
    computeMaxHeight(theme.mixins.toolbar.minHeight)
  );
  const { size } = useMainSize();

  const onResize = (_, { size: { width: newWidth } }) => {
    const dir = newWidth < width ? "left" : "right";
    if (dir === "right" && newWidth > 6 && newWidth < 150) {
      setWidth(150);
    } else if (dir === "left" && newWidth < 150) {
      setWidth(6);
    } else {
      setWidth(newWidth);
    }
  };

  useEffect(() => {
    const onResize = () => {
      const MAX_HEIGHT = computeMaxHeight(
        !document.fullscreenElement ? theme.mixins.toolbar.minHeight : 0
      );
      setHeight(MAX_HEIGHT);
    };
    window.addEventListener("resize", onResize);
    window.addEventListener("fullscreenchange", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("fullscreenchange", onResize);
    };
  }, [theme.mixins.toolbar.minHeight]);

  return (
    <StyledPanel
      height={height}
      width={width}
      resizeHandles={["e"]}
      handle={<CustomResizeHandle />}
      minConstraints={[6]}
      onResize={onResize}
      maxConstraints={[(size.clientWidth * 70) / 100, Infinity]}
    >
      <>
        {width > 6 &&
          panels.map((Component, index) => (
            <Component
              key={index}
              isOpen={activePanel.indexOf(index) !== -1}
              onClick={() => {
                const indexOf = activePanel.indexOf(index);
                if (indexOf !== -1) {
                  setActivePanel([
                    ...activePanel.slice(0, indexOf),
                    ...activePanel.slice(indexOf + 1),
                  ]);
                } else {
                  setActivePanel([...activePanel, index]);
                }
              }}
            />
          ))}
      </>
    </StyledPanel>
  );
}
