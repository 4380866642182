import React, { Fragment, lazy, Suspense, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Markdown from "../Markdown";
import { Grid, styled } from "@mui/material";

const BaseEditor = lazy(async () => {
  const module = await import("./MonacoEditor");
  return module;
});

const BoxMarkdownEditor = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  "& > .MuiButton-root": {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(2),
  },
}));
const BoxMarkdownPreview = styled(Box)(({ theme }) => ({
  border: "1px solid #ccc",
  height: "100%",
  width: "100%",
  padding: theme.spacing(1),
}));

export default function MarkdownEditor({ value, ...rest }) {
  const [open, setOpen] = useState(false);

  return (
    <Suspense fallback={Fragment}>
      <Grid container sx={{ width: "100%", height: "100%" }}>
        <Grid item xs>
          <BoxMarkdownEditor>
            <BaseEditor mode="markdown" value={value} {...rest} />
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpen(!open)}
            >
              Preview
            </Button>
          </BoxMarkdownEditor>
        </Grid>
        {open && (
          <Grid item xs>
            <BoxMarkdownPreview>
              <Markdown>{value}</Markdown>
            </BoxMarkdownPreview>
          </Grid>
        )}
      </Grid>
    </Suspense>
  );
}
