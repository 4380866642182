import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import { Link } from "react-router-dom";
import Container from "../lib/Container";
import useExercisesGroup from "../../hooks/functional/useExercisesGroup";

export default () => {
  const { loading, exercisesGroup: categories } = useExercisesGroup();
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Grid container spacing={4} direction={"column"}>
        <Grid item>
          <Typography variant="h2">Liste des exercises</Typography>
        </Grid>
        <Grid item container spacing={4}>
          {categories.map((category) => (
            <Grid item key={category.id} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardActionArea
                  LinkComponent={Link}
                  to={{
                    pathname: "/exercises/playground",
                    search: `?category=${category.id}&exercise=${
                      Object.entries(category.exercices).sort((a, b) =>
                        a[1].name.localeCompare(b[1].name)
                      )[0][1].id
                    }`,
                  }}
                  sx={{ height: "100%" }}
                >
                  <CardContent
                    sx={{
                      height: "100%",
                    }}
                  >
                    <Typography gutterBottom variant="h5" component="h2">
                      {category.category_name}
                    </Typography>
                    <Typography>
                      Number of exercises:{" "}
                      {Object.keys(category.exercices).length}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
          {!loading && categories.length === 0 && (
            <Typography
              variant="h5"
              component="h2"
              align="center"
              sx={{ width: "100%" }}
            >
              Pas d'exercices disponibles pour le moment
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
