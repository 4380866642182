import Cancel from "@mui/icons-material/Cancel";
import Check from "@mui/icons-material/Check";
import Timelapse from "@mui/icons-material/Timelapse";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";

import SimpleFileEditor from "../lib/Editor/SimpleFileEditor";
import MultiFileEditor from "../lib/Editor/MultiFileEditor";
import { useCallback, useMemo, useState } from "react";
import useAuth from "../../hooks/useAuth";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        padding: 10,
        backgroundColor: "#fafafa",
        flex: 1,
        maxHeight: "100%",
        overflow: "scroll",
      }}
    >
      {value === index && children}
    </div>
  );
}

export function TestResults({ results }) {
  const groupedResults = useMemo(
    () =>
      results.reduce((acc, result) => {
        const groupName = result.testGroup || "Default";
        acc[groupName] ??= {
          status: result.status,
          tests: [],
        };
        acc[groupName].status =
          acc[groupName].status === "KO" ? "KO" : result.status;
        acc[groupName].tests.push(result);
        return acc;
      }, {}),
    [results]
  );
  const listResultsComponents = useCallback(
    (results) => (
      <List>
        {results.map((test) => (
          <ListItem key={test.testName} sx={{ ml: 6 }}>
            <ListItemIcon>
              <ListItemIcon>
                {test.status === "OK" && <Check style={{ color: "green" }} />}
                {test.status === "KO" && <Cancel style={{ color: "red" }} />}
              </ListItemIcon>
            </ListItemIcon>
            <ListItemText
              primary={test.testName}
              secondary={test.reason}
              secondaryTypographyProps={{
                component: "p",
                style: { whiteSpace: "pre-line" },
              }}
            />
          </ListItem>
        ))}
      </List>
    ),
    []
  );

  return useMemo(
    () =>
      Object.keys(groupedResults).length === 1 ? (
        listResultsComponents(groupedResults.Default.tests)
      ) : (
        <List>
          {Object.keys(groupedResults).map((res) => (
            <>
              <ListItem key={res} sx={{ ml: 2 }}>
                <ListItemIcon>
                  <ListItemIcon>
                    {groupedResults[res].status === "OK" && (
                      <Check style={{ color: "green" }} />
                    )}
                    {groupedResults[res].status === "KO" && (
                      <Cancel style={{ color: "red" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText>{res}</ListItemText>
              </ListItem>
              {listResultsComponents(groupedResults[res].tests)}
            </>
          ))}
        </List>
      ),
    [groupedResults]
  );
}

export default function SubmissionModal({ submission, onClose }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedFile /*, setSelectedFile*/] = useState("index.js");
  const { customData } = useAuth();
  const preferredTheme = customData?.preferredTheme
    ? customData.preferredTheme
    : "monokai";
  const codeToDisplay = useMemo(() => {
    if (!submission) return "";
    let value = "";
    if (submission.output) value = submission.output.code_used;
    else value = submission.code;
    return typeof value === "string" ? { "index.js": value } : value;
  }, [submission, selectedFile]);

  const Editor = useMemo(
    () =>
      Object.keys(codeToDisplay).length === 1
        ? SimpleFileEditor
        : MultiFileEditor,
    [codeToDisplay]
  );

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={Boolean(submission)}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      sx={{ "& .MuiDialog-paper": { height: "100%" } }}
    >
      <DialogTitle id="simple-dialog-title">
        {Boolean(submission) && (
          <Grid container alignItems={"center"}>
            {submission.state === "processing" && (
              <Timelapse style={{ color: "blue" }} />
            )}
            {submission.state === "succeed" && (
              <Check style={{ color: "green" }} />
            )}
            {["failed", "expired"].includes(submission.state) && (
              <Cancel style={{ color: "red" }} />
            )}
            <Typography style={{ marginLeft: 10 }}>
              {`Version: ${format(submission.submittedAt, "yyyyMMdd-HHmm")}` +
                (submission.processedAt
                  ? ` - Processed at: ${format(
                      submission.processedAt,
                      "yyyy-MM-dd HH:mm"
                    )}`
                  : "")}
            </Typography>
          </Grid>
        )}
      </DialogTitle>

      <Tabs
        value={tabIndex}
        onChange={(e, val) => setTabIndex(val)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Results" />
        <Tab label="Code used" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        {Boolean(submission) && submission && submission.output && (
          <TestResults results={submission.output.results} />
        )}
        {Boolean(submission) &&
          submission &&
          submission.output &&
          submission.output.results.length === 1 && (
            <ListItem style={{ marginLeft: 16 }}>
              <ListItemIcon>
                <ListItemIcon>
                  <Cancel style={{ color: "red" }} />
                </ListItemIcon>
              </ListItemIcon>
              <ListItemText
                primary={"Something went wrong with your code"}
                secondaryTypographyProps={{
                  component: "p",
                  style: { whiteSpace: "pre-line" },
                }}
              />
            </ListItem>
          )}
        {Boolean(submission) && submission.isExpired() && (
          <ListItem style={{ marginLeft: 16 }}>
            <ListItemIcon>
              <ListItemIcon>
                <Cancel style={{ color: "red" }} />
              </ListItemIcon>
            </ListItemIcon>
            <ListItemText
              primary={"Timeout"}
              secondary={"Check for infinite loops or memory issues"}
              secondaryTypographyProps={{
                component: "p",
                style: { whiteSpace: "pre-line" },
              }}
            />
          </ListItem>
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Editor
          readOnly={true}
          code={codeToDisplay}
          theme={preferredTheme || "monokai"}
        />
      </TabPanel>
    </Dialog>
  );
}
