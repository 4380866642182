import Box from "@mui/material/Box";

const classes = {
  bar: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    height: "33px",
    maxHeight: "33px",
    backgroundColor: "#2d3233",
    width: "100%",
    alignItems: "center",
  },
  tab: {
    display: "inline-flex",
    height: "100%",
    cursor: "pointer",
    alignItems: "center",
    color: "#919191",
    padding: "0.3rem 0.4rem 0.3rem 0.6rem !important",
    fontSize: "0.75rem",
    fontWeight: 400,
    borderRight: "1px solid #252526",
    backgroundColor: "#2d3233",
  },
};

export function Tab({ label, onClick, selected, children, style = {} }) {
  return (
    <Box
      component="span"
      sx={{
        ...classes.tab,
        backgroundColor: selected ? "#1D2021" : "#2d3233",
        borderBottom: selected ? "2px solid blue" : "none",
        ...style,
      }}
      onClick={onClick}
    >
      {children || label}
    </Box>
  );
}

export default function Tabs({ children, style = {} }) {
  return <Box sx={{ ...classes.bar, ...style }}>{children}</Box>;
}
