import { collection, onSnapshot, query } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { firestore } from "../../firebaseConfig";
import useAuth from "../useAuth";
import { documentId, where } from "@firebase/firestore";
import { arrayUnique, chunks } from "../../utils/string";

export const ExercisesGroupContext = createContext();

function grabExercisesGroup(constraints = [], callback) {
  const docRef = collection(firestore, "exercises");
  const q = query(docRef, ...constraints);
  return onSnapshot(q, (snapshot) => {
    callback(
      snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
    );
  });
}

function grabPublications(constraints = [], callback) {
  const docRef = collection(firestore, "exercises-publication");
  const q = query(docRef, ...constraints);
  return onSnapshot(q, (snapshot) => {
    callback(
      snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
    );
  });
}

export function ExercicesGroupProvider({ children }) {
  const { customData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [publications, setPublications] = useState(null);
  const [exercisesGroup, setExercisesGroup] = useState([]);

  useEffect(() => {
    if (customData)
      if (customData.isAdmin) {
        setPublications([]);
      } else {
        const constraints = customData?.groups?.length
          ? [
              where(
                "group.id",
                "in",
                customData.groups.map((group) => group.id) || null
              ),
            ]
          : [where("group", "==", false)];
        const publicationSnapshot = grabPublications(
          constraints,
          setPublications
        );
        return () => publicationSnapshot();
      }
  }, [customData]);

  useEffect(() => {
    if (publications !== null) {
      let chunkDoneCount = 0;
      const exercices = [];
      const chunkedPublications = chunks(
        publications.filter(
          (p) =>
            !p.timeLimited ||
            (p.dateStart.toDate() < new Date() &&
              p.dateEnd.toDate() > new Date())
        ),
        10
      );
      if (!chunkedPublications.length) {
        if (customData.isAdmin) {
          chunkedPublications.push([]);
        } else {
          setExercisesGroup([]);
          setLoading(false);
          return;
        }
      }
      const snapshots = chunkedPublications.map((chunk) => {
        const constraints = chunk.length
          ? [
              where(
                documentId(),
                "in",
                chunk.map((ep) => ep.category.id)
              ),
            ]
          : [];
        return grabExercisesGroup(constraints, (items) => {
          exercices.push(...items);
          chunkDoneCount++;
          if (chunkDoneCount === chunkedPublications.length) {
            setExercisesGroup(arrayUnique(exercices, (e) => e.id));
            setLoading(false);
          }
        });
      });

      return () => snapshots.forEach((e) => e());
    }
  }, [publications]);

  const selectors = {
    getCategory(id) {
      return exercisesGroup.find((eg) => eg.id === id);
    },
  };

  return (
    <ExercisesGroupContext.Provider
      value={{ loading, exercisesGroup, ...selectors }}
    >
      {children}
    </ExercisesGroupContext.Provider>
  );
}

export default function useExercisesGroup() {
  return useContext(ExercisesGroupContext);
}
