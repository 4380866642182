import React, { Fragment, lazy, Suspense, useEffect } from "react";

const BaseEditor = lazy(async () => {
  const module = await import("./MonacoEditor");
  return module;
});

export default function PhpEditor({ onChange, value, ...rest }) {
  useEffect(() => {
    if (value !== undefined && !value.startsWith("<?php")) {
      onChange("<?php\n\n" + value);
    }
  }, [value]);

  return (
    <Suspense fallback={Fragment}>
      <BaseEditor onChange={onChange} mode="php" value={value} {...rest} />
    </Suspense>
  );
}
