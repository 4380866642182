import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MarkdownEditor from "../../../lib/Editor/MarkdownEditor";

export default function General({ exerciseGroup, onSave }) {
  return (
    <Grid item container spacing={2} direction="column" xs>
      <Grid item>
        <TextField
          variant="outlined"
          required
          label="Title"
          name="category_name"
          autoFocus
          fullWidth
          value={exerciseGroup.category_name}
          onChange={(event) => onSave({ category_name: event.target.value })}
        />
      </Grid>
      <Grid item xs>
        <MarkdownEditor
          onChange={(value) => onSave({ description: value })} // send value to hook form
          value={exerciseGroup.description}
          name={"description"}
          placeholder="Informations sur le groupe d'exercices"
        />
      </Grid>
    </Grid>
  );
}
